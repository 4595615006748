import React, { useState, useEffect } from "react";
import UserCard from "./userCard/UserCard";
import "./UsersCardContainer.scss";
import { API_BASE_URL } from "../../../Constants/constants";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import DisableUser from "./DisableUser/DisableUser";

function UsersCardContainer() {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [inputEnabled, setInputEnabled] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: 2,
    pageSize: 100,
    totalCount: 0,
    totalPage: 1,
  });

  const addToSelectedList = (partyId, enabled) => {
    if (!partyId) {
      return;
    }  
    setSelectedList((currentList) => {
      const itemIndex = currentList.findIndex((item) => item.partyId === partyId);
  
      if (itemIndex > -1) {
        return currentList.filter((item) => item.partyId !== partyId);
      } else {
        return [...currentList, { partyId, enabled: enabled === "Y" }];
      }
    });
  };
  const callUserListAPI = async (page = pagination.currentPage) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}user/getUserList`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          viewIndex: page.toString(),
          viewSize: pagination.pageSize.toString(),
          extraDetails: "true",
          searchBy: [],
        }),
      });

      if (response.status === 200) {
        const result = await response.json();
        setPagination((prevPagination) => ({
          ...prevPagination,
          currentPage: page,
          nextPage: page + 1,
          totalCount: result.paginationMap.totalCount,
          totalPage: Math.ceil(result.paginationMap.totalCount / 100),
        }));
        setUsers((prevUsers) => [...prevUsers, ...result.userList]);
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    callUserListAPI();
  }, []);

  const loadMoreUsers = () => {
    if (pagination.currentPage < pagination.totalPage) {
      callUserListAPI(pagination.nextPage);
    }
  };

  const handleCheckboxChange = (index, userId) => {
    setInputEnabled((prevEnabled) => {
      const updatedEnabled = [...prevEnabled];
      updatedEnabled[index] = !updatedEnabled[index];
      return updatedEnabled;
    });
  };
  return (
    <div className="usersCardContainer">
      {isLoading ? (
        <div className="height100_asset displayGrid">
          <LoaderStyle />
        </div>
      ) : (
        <>
          {selectedList.length > 0 && (
            <DisableUser users={users} payLoadDisableUser={selectedList} />
          )}
          {users.map((el, index) => (
            <UserCard
              key={el.userBasicDetails[0]?.partyId || index}
              data={el}
              addToSelectedList={addToSelectedList}
              userIndex={index}
              images={[]}
              inputEnabled={inputEnabled[index]}
              handleCheckboxChange={() =>
                handleCheckboxChange(index, el.userBasicDetails[0]?.partyId)
              }
            />
          ))}
          <div className="UsersLoadBtnCont">
            {pagination.currentPage < pagination.totalPage && (
              <button className="loadMoreBtn" onClick={loadMoreUsers}>
                Load more
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default UsersCardContainer;
