import ReactEcharts from "echarts-for-react";
import EnergyIcon from "../../assets/icons/energyIcon.svg";
import waterConsumedIcon from '../../assets/icons/water-consumed-icon.svg';
import airConsumedIcon from '../../assets/icons/air-consumed-icon.svg';
import gasConsumedIcon from '../../assets/icons/gas-consumed-icon.svg';
import fuelConsumedIcon from '../../assets/icons/fuel-consumed-icon.svg';
import "./SingleFacilityEnergyConsumption.scss";
import { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EnergyActiveIcon from "../../assets/icons/energyActiveIcon.svg"
import AirActiveIcon from "../../assets/icons/airActiveIcon.svg"
import FuelActiveIcon from "../../assets/icons/fuelActiveIcon.svg"
import GasActiveIcon from "../../assets/icons/gasActiveIcon.svg"
import WaterActiveIcon from "../../assets/icons/waterActiveIcon.svg"
import EnergyInActiveIcon from "../../assets/icons/energyInActiveIcon.svg"
import AirInActiveIcon from "../../assets/icons/airInActiveIcon.svg"
import FuelInActiveIcon from "../../assets/icons/fuelInActiveIcon.svg"
import GasInaActiveIcon from "../../assets/icons/gasInActiveIcon.svg"
import WaterInActiveIcon from "../../assets/icons/waterInActiveIcon.svg"
import airMeterIcon from "../../assets/icons/air-main-meter-icon.svg"
import airSubMeterIcon from "../../assets/icons/air-sub-meter-icon.svg"
import waterMeterIcon from "../../assets/icons/water-main-meter-icon.svg"
import waterSubMeterIcon from "../../assets/icons/water-sub-meter-icon.svg"
import gasMeterIcon from "../../assets/icons/gas-main-meter-icon.svg"
import gasSubMeterIcon from "../../assets/icons/gas-sub-meter-icon.svg"
import fuelMeterIcon from "../../assets/icons/fuel-main-meter-icon.svg"
import fuelSubMeterIcon from "../../assets/icons/fuel-sub-meter-icon.svg"
import EnergyCardComponent from "./EnergyCardComponent";
import AirCardComponent from "./AirCardComponent";
import WaterCardComponent from "./WaterCardComponent";
import IconClose from "../../assets/icons/IconClose.svg";
import ResetIcon from "../../assets/icons/iconReset.svg"
import SettingsIcon from "../../assets/icons/iconSettigs.svg"
import { Modal } from "react-bootstrap";
import { API_BASE_URL } from "../../Constants/constants";
import DisplayError from "../DisplayError/DisplayError";
import moment from "moment";
import { useStateValue } from "../../redux/StateProvider";
import CloseIcon from "../../assets/icons/IconClose.svg";
import ChipCloseIcon from "../../assets/icons/chipsCloseIcon.svg";
import FilterIcon from "../../assets/icons/filterArrowIcon.svg";
import upArrow from '../../assets/image/upArrow.svg';
import downArrow from '../../assets/image/downArrow.svg'
import GasCardComponent from "./GasCardComponent";
import FuelCardComponent from "./FuelCardComponent";
import DashboardFilter from "./DashboardFilter";

// interface propsDatatype {
//   energyConsumptionFilter: boolean;
//   setEnergyConsumptionFilter: Function;
// }
interface Facility {
  facilityId: string;
  parentFacilityGroupType: string;
  facilityName: string;
  parentFacilityGroupId: string;
}
interface filteredDataTypes {
  stateIds: string[];
  countryIds: string[];
  regionIds: string[];
  facilityIds: Facility[];
  clusterIds: {
    facilityGroupTypeId: string;
    parentFacilityGroupType: string;
    facilityGroupId: string;
    facilityGroupName: string;
    parentFacilityGroupId: string;
  }[];
  cityIds: {
    facilityGroupTypeId: string;
    parentFacilityGroupType: string;
    facilityGroupId: string;
    facilityGroupName: string;
    parentFacilityGroupId: string;
  }[];
}

type ChartsDropDownData = {
  [key: string]: string[];
};

function SingleFacilityEnergyConsumption(props: any) {
  const { fullSizedComponents, showSettingsPopup, setShowSettingsPopup, energyConsumptionFilter, setEnergyConsumptionFilter } = props;

  useEffect(() => {
    callingUserSpecificAPIForDecimal();
  }, [])
  const [decimalVal, setDecimalVal] = useState<any>(0)
  // const facilityList: any = localStorage.getItem("facilityIds");
  const firstFacilityId = '_NA_'
  // JSON.parse(facilityList)[0].facilityId;
  const [currentFilterByTypeAndId, setCurrentFilterByTypeAndId] = useState({
    filterByType: "_NA_",
    filterById: firstFacilityId,
  });

  useEffect(()=>{
    console.log('ActiveFilter::',currentFilterByTypeAndId)
  },[currentFilterByTypeAndId])

  const [mainMeterSubMeterPermissions, setMainMeterSubMeterPermissions] = useState([]);

  const [tabsArr, setTabsArr] = useState<any>([{ name: 'Energy', abbreviation: 'kWh', id: 0, activeIcon: EnergyActiveIcon, inActiveIcon: EnergyInActiveIcon, consumedIcon: EnergyIcon, meterIcon: '', subMeterIcon: '' },
  { name: 'Air', abbreviation: 'm3', id: 1, activeIcon: AirActiveIcon, inActiveIcon: AirInActiveIcon, consumedIcon: airConsumedIcon, meterIcon: airMeterIcon, subMeterIcon: airSubMeterIcon },
  { name: 'Water', abbreviation: 'KL', id: 2, activeIcon: WaterActiveIcon, inActiveIcon: WaterInActiveIcon, consumedIcon: waterConsumedIcon, meterIcon: waterMeterIcon, subMeterIcon: waterSubMeterIcon },
  { name: "Gas", abbreviation: '', id: 3, activeIcon: GasActiveIcon, inActiveIcon: GasInaActiveIcon, consumedIcon: gasConsumedIcon, meterIcon: gasMeterIcon, subMeterIcon: gasSubMeterIcon },
  { name: 'Fuel', abbreviation: '', id: 4, activeIcon: FuelActiveIcon, inActiveIcon: FuelInActiveIcon, consumedIcon: fuelConsumedIcon, meterIcon: fuelMeterIcon, subMeterIcon: fuelSubMeterIcon }])

  const [activeTab, setActiveTab] = useState(0);
  const [shiftFlag, setShiftFlag] = useState(false);

  const tabArr = [{ name: 'Energy', component: <EnergyCardComponent mainMeterSubMeterPermissions={mainMeterSubMeterPermissions} shiftFlag={shiftFlag} fullSizedComponents={fullSizedComponents} decimalVal={decimalVal} currentFilterByTypeAndId={currentFilterByTypeAndId} />, abbreviation: 'kWh', id: 0, activeIcon: EnergyActiveIcon, inActiveIcon: EnergyInActiveIcon, consumedIcon: EnergyIcon, meterIcon: '', subMeterIcon: '' },
  { name: 'Air', component: <AirCardComponent mainMeterSubMeterPermissions={mainMeterSubMeterPermissions} shiftFlag={shiftFlag} fullSizedComponents={fullSizedComponents} decimalVal={decimalVal} currentFilterByTypeAndId={currentFilterByTypeAndId} />, abbreviation: 'm3', id: 1, activeIcon: AirActiveIcon, inActiveIcon: AirInActiveIcon, consumedIcon: airConsumedIcon, meterIcon: airMeterIcon, subMeterIcon: airSubMeterIcon },
  { name: 'Water', component: <WaterCardComponent mainMeterSubMeterPermissions={mainMeterSubMeterPermissions} shiftFlag={shiftFlag} fullSizedComponents={fullSizedComponents} decimalVal={decimalVal} currentFilterByTypeAndId={currentFilterByTypeAndId} />, abbreviation: 'KL', id: 2, activeIcon: WaterActiveIcon, inActiveIcon: WaterInActiveIcon, consumedIcon: waterConsumedIcon, meterIcon: waterMeterIcon, subMeterIcon: waterSubMeterIcon },
  { name: "Gas",   component: <GasCardComponent mainMeterSubMeterPermissions={mainMeterSubMeterPermissions} shiftFlag={shiftFlag} fullSizedComponents={fullSizedComponents} decimalVal={decimalVal} currentFilterByTypeAndId={currentFilterByTypeAndId} />, abbreviation: 'kg', id: 3, activeIcon: GasActiveIcon, inActiveIcon: GasInaActiveIcon, consumedIcon: gasConsumedIcon, meterIcon: gasMeterIcon, subMeterIcon: gasSubMeterIcon },
  { name: 'Fuel', abbreviation: 'L', component: <FuelCardComponent mainMeterSubMeterPermissions={mainMeterSubMeterPermissions} shiftFlag={shiftFlag} fullSizedComponents={fullSizedComponents} decimalVal={decimalVal} currentFilterByTypeAndId={currentFilterByTypeAndId} />, id: 4, activeIcon: FuelActiveIcon, inActiveIcon: FuelInActiveIcon, consumedIcon: fuelConsumedIcon, meterIcon: fuelMeterIcon, subMeterIcon: fuelSubMeterIcon }]

  const getTabs = () => {
    let loginData: any = localStorage.getItem("aoneLoginData");
    if(JSON.parse(loginData).loginDetails[0].product !== undefined){
    let subscribedTabs =
       JSON.parse(loginData)
      .loginDetails[0].product.map((obj: any) => obj.productName.split(" ")[1]);
    setTabsArr(() => tabArr.filter((obj) => subscribedTabs.includes(obj.name)).map((obj, index) => ({ ...obj, id: index })))
    console.log("TabArr:",tabsArr);
    }else{
      setMainRespStat('empty')
    }
  }


  const setDecimalValues = (val: any) => {
    if (val >= 0 && val <= 4) {
      setDecimalVal(val);
    }
  }
  const restoreDecimalValue = useRef(0);
  const [shiftTogglePermission, setShiftTogglePermission] = useState(false);
  const callingUserSpecificAPIForDecimal = async (val = 'empty') => {
    const token: any = localStorage.getItem("@tokenID");

    if(val === 'empty'){
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      }
      const data = await fetch(`${API_BASE_URL}/assets/userSpecificDataNew`, options)
      const response = await data.json();
        restoreDecimalValue.current = response?.data?.all?.preferences?.
        filter((obj: any) => obj.portalPortletId === 'CONSUMPTION_V1')[0]?.userPrefValue !== undefined ?
        +response?.data?.all?.preferences?.
        filter((obj: any) => obj.portalPortletId === 'CONSUMPTION_V1')[0]?.userPrefValue : 0
        setDecimalVal(response?.data?.all?.preferences?.filter((obj: any) => obj.portalPortletId === 'CONSUMPTION_V1')[0]?.userPrefValue !== undefined ? +response?.data?.all?.preferences?.filter((obj: any) => obj?.portalPortletId === 'CONSUMPTION_V1')[0]?.userPrefValue : 0)

      if (response?.data?.all?.preferences?.filter((obj: any) => obj?.userPrefTypeId === 'SHIFT_ENABLED')[0]?.userPrefValue !== undefined) {
        if(response?.data?.all?.preferences?.filter((obj: any) => obj?.userPrefTypeId === 'SHIFT_ENABLED')[0]?.userPrefValue == 'Y') {
          setShiftTogglePermission(true);
        }
      } else if (response?.data?.all?.partyPreferences?.filter((obj: any) => obj?.partyPrefTypeId === 'SHIFT_ENABLED')[0]?.partyPrefValue !== undefined) {
        if(response?.data?.all?.partyPreferences?.filter((obj: any) => obj?.partyPrefTypeId === 'SHIFT_ENABLED')[0]?.partyPrefValue == 'Y') {
          setShiftTogglePermission(true);
        }
      }
      let dummyArr :any = [];
      response?.data?.all?.preferences?.forEach((el:any)=>{
        if(el.userPrefTypeId === "CNSP_MAIN_ENABLED" || el.userPrefTypeId === "CNSP_SUB_ENABLED"){
          if(el.userPrefValue === 'Y' ){
            dummyArr.push(el.userPrefTypeId);
          }
        }
      })
      response?.data?.all?.partyPreferences?.forEach((el:any)=>{
        if(el.partyPrefTypeId === "CNSP_MAIN_ENABLED" || el.partyPrefTypeId === "CNSP_SUB_ENABLED"){
          if(el.partyPrefValue === 'Y' && !dummyArr.includes(el.partyPrefTypeId)){
            dummyArr.push(el.partyPrefTypeId);
          }
        }
      })

      response?.data?.all?.preferences?.forEach((el:any)=>{
        if(el?.userPrefTypeId === "CNSP_MAIN_ENABLED" || el?.userPrefTypeId === "CNSP_SUB_ENABLED"){
          if(el?.userPrefValue === 'N' ){
            dummyArr = dummyArr.filter((e:string)=> e !== el?.userPrefTypeId )
          }
        }
      })
      

      // dummyArr.push('CNSP_MAIN_ENABLED');
      // dummyArr.push('CNSP_SUB_ENABLED');
      setMainMeterSubMeterPermissions(dummyArr);

      // SettingUp Default... SHIFT CHOICE..
      if(response?.data?.all?.preferences?.filter((obj: any) => obj?.userPrefTypeId === 'SHIFT_DEFAULT')[0]?.userPrefValue !== undefined){
        if(response?.data?.all?.preferences?.filter((obj: any) => obj?.userPrefTypeId === 'SHIFT_DEFAULT')[0]?.userPrefValue == 'Y'){
          setShiftFlag(true);
        }else{
          setShiftFlag(false);
        }
      }
        // SHIFT_DEFAULT

        getTabs();
    }else{
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body : JSON.stringify({
           "preference" : "UserPreference",
           "prefValue" : "" + val,
           "prefTypeId" : "CNSP_DECIMAL_SETTING",
           "portletId" : "CONSUMPTION_V1" })
      }
      try {
        const data = await fetch(`${API_BASE_URL}/assets/createPreference`, options)
        if(data.status === 200){
          setDecimalVal(val)
        }
        setDecimalVal(val)
          getTabs();
      } catch (error) {
        setDecimalVal(val)
      }

    }
  }

  // Filter Functionality............................................

  const [{ dateRange, dateChip }] = useStateValue();
  const [filterData, setFilterData] = useState<filteredDataTypes | any>();
  const hierarchyLevels = [
    "countryIds",
    "stateIds",
    "regionIds",
    "cityIds",
    "clusterIds",
    "facilityIds",
  ];

  const [mappingArr, setMappingArr] = useState<
    {
      dropDownName: string;
      values: any[];
      show: boolean;
      selectedValue: string;
      selectedId: string;
    }[]
  >([
    {
      dropDownName: "",
      values: [],
      show: false,
      selectedValue: "",
      selectedId: "",
    },
  ]);
  const [arrayWithData, setArraysWithData] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const findHierarchyLevelsToDisplay = (filterData: any) => {
    let arrayWithNonEmptyValues: string[] = [];
    let tempArr = Object.keys(filterData).filter(
      (ele) => filterData[ele as keyof filteredDataTypes].length !== 0
    );
    hierarchyLevels.forEach((el) =>
      tempArr.includes(el) ? arrayWithNonEmptyValues.push(el) : ""
    );
    setArraysWithData(arrayWithNonEmptyValues);
    console.log("TempArr:",tempArr);
  };

  const pushDataToMappingArr = (keyName: string) => {
    if (mappingArr[0].values.length === 0) {
      setMappingArr([
        {
          dropDownName: keyName,
          values: filterData[keyName as keyof filteredDataTypes],
          show: true,
          selectedValue: "",
          selectedId: "",
        },
      ]);
      setCurrentIndex(currentIndex + 1);
      return;
    }

    const index = hierarchyLevels.indexOf(keyName);

    setMappingArr((element) => [
      ...element,
      {
        dropDownName: keyName,
        values: filterData[keyName as keyof filteredDataTypes].filter(
          (obj: any) =>
            obj.parentFacilityGroupId ===
            element.find(
              (name) => name.dropDownName === hierarchyLevels[index - 1]
            )?.selectedId
        ),
        show: true,
        selectedValue: "",
        selectedId: "",
      },
    ]);
    setCurrentIndex(currentIndex + 1);
    console.log("mappingArr:",mappingArr);
  };

  const [showSelectedFilterChipName, setSelectdFilterChipName] = useState<any>([]);

  useEffect(() => {
    const filterDataString = localStorage.getItem("filterRelatedData");
    const filterObj = JSON.parse(filterDataString ?? "{}");
    setFilterData(filterObj);
    findHierarchyLevelsToDisplay(filterObj);
  }, []);

  const filterBasedOnSelectedItem = (
    id: string,
    selectedName: string,
    dropDownName: string,
    type : string
  ) => {
    showContentLoader.current = true;

    setSelectdFilterChipName((prevSelectedFilters: any[]) => {
      const elementExists = prevSelectedFilters.some(
        (filter) => filter.type === type
      );
      if (elementExists) {
        return prevSelectedFilters.map((filter) =>
          filter.type === type ? { type: type, selectedId: id, selectedValue: selectedName } : filter
        );
      } else {
        return [...prevSelectedFilters, { type: type, selectedId: id, selectedValue: selectedName }];
      }
    });

    setMappingArr((ele) =>
    {

      let data = ele.map((obj: any) => {
        if (obj.dropDownName === dropDownName) {
          return {
            ...obj,
            selectedValue: selectedName,
            selectedId: id,
            show: obj.show,
          };
        } else {
          return obj;
        }
      })
      let index = data.findIndex((obj) => obj.selectedId === id);
      if(data[index+1] !== undefined){
        data = data.slice(0,index+1)
        setCurrentIndex(index+1);
        if(showSelectedFilterChipName.length > 1){
             setSelectdFilterChipName((element:any) => element.slice(0, index +1));
        }
      }
      return data
    }
    );
    const index = hierarchyLevels.indexOf(dropDownName);
    setSelectorChip(true);
    setCurrentFilterByTypeAndId({
      filterByType: dropDownName.slice(0, -3),
      filterById: id,
    });
  };

  const [showSelectorChip, setSelectorChip] = useState(true);
  const showContentLoader = useRef(false);
  useEffect(() => {
    // setMappingArr([
    //   {
    //     dropDownName: "",
    //     values: [],
    //     show: false,
    //     selectedValue: "",
    //     selectedId: "",
    //   },
    // ]);
    // setEnergyConsumptionFilter(false);
    // setCurrentFilterByTypeAndId({
    //   filterByType: "facility",
    //   filterById: '_NA_',
    // });
    showContentLoader.current = false;
  }, [dateChip, dateRange]);

  const [mainRespStat, setMainRespStat] = useState('');

  useEffect(() => {
    getTabs();
  }, [fullSizedComponents,mainMeterSubMeterPermissions, currentFilterByTypeAndId,showSelectedFilterChipName, decimalVal, shiftTogglePermission, shiftFlag])

  return (
    <>
    { mainRespStat === "empty" ? (
        <DisplayError type={"err-empty"} />
      ) : mainRespStat === "error" ? (
        <DisplayError type={"err-500/404"} />
      ) :
      <div className="energyConsumptionContainer">
        {/* filter functionality */}
        {energyConsumptionFilter && (
          <DashboardFilter setEnergyConsumptionFilter={setEnergyConsumptionFilter} setCurrentFilterByTypeAndId={setCurrentFilterByTypeAndId}  />
        )}

        {/*Consumption Tabs Render here */}
        <div className="energyConsumptionTabsCont">
          {
            tabsArr.map((obj: any) => <div style={{ border: obj.id === activeTab ? '' : 'none' }} key={obj.id} onClick={() => { setActiveTab(obj.id) }} className='toastTabSwitch'>
              <img src={obj.id === activeTab ? obj.activeIcon : obj.inActiveIcon} alt="icon" className="tabsIcons" />
              <p style={{ color: obj.id === activeTab ? '' : 'black' }}>{obj.name}</p>
            </div>)
          }
        </div>

        {shiftTogglePermission && <div className="ShiftFlagContainer">
            <p>VIEW DATA BASED ON</p>
            <button onClick={()=> setShiftFlag(false)} style={{backgroundColor : shiftFlag ? '' : 'rgb(56,77,198)', color : shiftFlag ? '' : 'white'}} >DAILY CONSUMPTION</button>
            <button onClick={()=> setShiftFlag(true)} style={{backgroundColor : shiftFlag ? 'rgb(56,77,198)' : '', color : shiftFlag ? 'white' : ''}} >SHIFT TIMINGS</button>

          </div>}

        {/* Components will render according to above tabs selection  */}
        {tabsArr[activeTab]?.component}
      </div>
      }
      <Modal
        show={showSettingsPopup}
        onHide={() => {setShowSettingsPopup(false); setDecimalVal(restoreDecimalValue.current);}}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="energyConsumptionPopup"

      >
        <Modal.Header className="energyConsumptionHeaderCont">
          <div className="energyHeaderIconTextCont">
            <img src={SettingsIcon} alt="icon" />
            <h4 className="energyPopupHeader">Settings</h4>
          </div>
          <div className="closeIconDiv" onClick={() =>{setShowSettingsPopup(false); setDecimalVal(restoreDecimalValue.current);}}>
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="energyConsumptionBodyCont">
          <div className="energyBodayDetailsCont">
            <p className="energyDetailsText">Decimal Precision for Consumption</p>
            <div className="input-cont">
              <input type="number" value={decimalVal} readOnly onChange={(e) => setDecimalValues(e.target.value)} className="energyPopupInput" />
              <div className="arrow-cont">
                <button onClick={() => setDecimalValues(decimalVal + 1)}><img src={upArrow} alt="Arrow" /></button>
                <button onClick={() => setDecimalValues(decimalVal - 1)} className="downButton"><img src={downArrow} alt="Arrow" /></button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="energyConsumptionNavCont">
          <div className="consumptionNavDiv">
            <div className="energyResetBtnCont" onClick={() => setDecimalVal(0)}>
              <img src={ResetIcon} alt="icon" />
              <p className="resetBtnText">Reset to defaults</p>
            </div>

            <div className="energyConsumptionBtnsCont">
              <button
                className="energyCancelBtn" onClick= {() => {setShowSettingsPopup(false); setDecimalVal(restoreDecimalValue.current);}}
              >
                Cancel
              </button>
              <button
                className="energyApplyBtn" onClick={() =>{callingUserSpecificAPIForDecimal(decimalVal); setShowSettingsPopup(false) }}
              >
                Apply
              </button>
            </div>
          </div>

        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SingleFacilityEnergyConsumption;

