import LocalStorageService from "../../../Utils/LocalStorageService";
import {ReportCardData} from "../DataTypes";
import { SelectedOptionType } from "../types";
import {FilterDataObj} from "../../../features/assetList/types";

export class ReportClass {

    getFacilityList = () => {
        const response = LocalStorageService.getFacilityData();
        const result: SelectedOptionType[] = response.map((item: any) => {
            return {
                value: item?.facilityId,
                label: item?.facilityName,
                key: item?.facilityId,
            }
        })
        return result;
    }

    getAssetCategoryFilterData = () => {
        const assetCategoryList: {
            parentDescription: string;
            fixedAssetTypeId: string;
            description: string;
            parentFixedAssetTypeId: string;
        }[] = LocalStorageService.getUserSpecificAssetTypeData();

        const result: any[] = Array.from(
            new Set(assetCategoryList?.map((type) => type.parentFixedAssetTypeId))
        ).map((parentFixedAssetTypeId) => {
            const firstMatch = assetCategoryList.find(
                (type) => type.parentFixedAssetTypeId === parentFixedAssetTypeId
            );
            return {
                id: parentFixedAssetTypeId,
                name: firstMatch?.parentDescription,
            };
        });
        return result;
    };

    getAssetTypeFilterData = () => {
        const assets: {
            parentDescription: string;
            fixedAssetTypeId: string;
            description: string;
            parentFixedAssetTypeId: string;
        }[] = LocalStorageService.getUserSpecificAssetTypeData();

        const result: any[] =
            assets?.map((element) => ({
                id: element.fixedAssetTypeId,
                name: element.description || element.fixedAssetTypeId,
                parentId: element.parentFixedAssetTypeId,
            })) || [];
        return result;
    };

    filterAttributes = (attributeProperties: any, attributeName: string, attributeNameMs: string) => {
        const hasAttribute = attributeProperties.some(item =>
            item.attributeName === attributeName && item.attributeValue === "TRUE");
        const hasAttributeMs = attributeProperties.some(item =>
            item.attributeName === attributeNameMs && item.attributeValue === "TRUE");

        if (hasAttribute && hasAttributeMs) {
            attributeProperties = attributeProperties.filter(item =>
                !(item.attributeName === attributeNameMs && item.attributeValue === "TRUE"));
        }

        return attributeProperties;
    }

    filterPriorityAttributes = (attributeProperties: any, attribute1: string, attribute2: string, lowPriorityAttribute: string) => {
        const hasAttribute1 = attributeProperties.some(item => item.attributeName === attribute1 && item.attributeValue === "TRUE");
        const hasAttribute2 = attributeProperties.some(item => item.attributeName === attribute2 && item.attributeValue === "TRUE");

        if (hasAttribute1 && hasAttribute2) {
            attributeProperties = attributeProperties.filter(item => !(item.attributeName === lowPriorityAttribute && item.attributeValue === "TRUE"));
        }

        return attributeProperties;
    }

    formatData = (data: ReportCardData) => {
        let attributeProperties = data.attributeProperties;

        const dateSelectorIds = ["DATE", "DATE_RANGE", "TIME", "TIME_RANGE", "DATE_TIME", "DATE_TIME_RANGE","TREND_DATE_PICKER"];
        const status = attributeProperties.filter((item) =>
            dateSelectorIds.includes(item.attributeName) && item.attributeValue === "TRUE");

        if (status.length === 0) {
            attributeProperties.push(
                {
                    "mandate": "N",
                    "attributeValue": "TRUE",
                    "uomAbbr": "null",
                    "attributeName": "DATE_RANGE",
                    "uomId": null,
                    "fieldTypeId": "DATE_RANGE",
                    "tableName": "ContentAttribute"
                }
            );
        }

        attributeProperties = this.filterAttributes(attributeProperties, "ASSET_CATEGORY", "ASSET_CATEGORY_MS");
        attributeProperties = this.filterAttributes(attributeProperties, "ASSET_TYPE", "ASSET_TYPE_MS");
        attributeProperties = this.filterAttributes(attributeProperties, "ASSET", "ASSET_MS");
        attributeProperties = this.filterAttributes(attributeProperties, "FACILITY", "FACILITY_MS");
        attributeProperties = this.filterPriorityAttributes(attributeProperties, "DATE", "DATE_RANGE", "DATE")
        attributeProperties = this.filterPriorityAttributes(attributeProperties, "TIME", "TIME_RANGE", "TIME")
        attributeProperties = this.filterPriorityAttributes(attributeProperties, "DATE_RANGE", "TREND_DATE_PICKER", "DATE_RANGE")

        return {
            ...data,
            attributeProperties: attributeProperties,
        };

    }
}