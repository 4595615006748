import React, { useEffect, useState } from "react";
import DisableIcon from '../../../../assets/icons/DisableIcon.svg';
import './DisableUser.scss';
import '../UsersCardContainer.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import IconClose from '../../../../assets/icons/IconClose.svg';
import { DisableUserApi } from "../userCard/service/ViewUserPostData";

const DisableUser = (props) => {
  const { users, payLoadDisableUser } = props;
  const [showDisablePopUp, setShowDisablePopUp] = useState(false);
  const [selectedList, setSelectedList] = useState(payLoadDisableUser);
  const [inputEnabled, setInputEnabled] = useState(users.map(() => false));

  useEffect(() => {
    setSelectedList(payLoadDisableUser);
  }, [payLoadDisableUser]);

  const selectAll = () => {
    const allUserIds = users.map(user => user?.userLoginDetails?.[0]?.partyId);
    setSelectedList(allUserIds);
    setInputEnabled(users.map(() => true));
  };

  const deselectAll = () => {
    setSelectedList([]); 
    setInputEnabled(users.map(() => false));
  };

  const handleBulkAction = () => {
    DisableUserApi(selectedList);
  };

  return (
    <>
      <div className="DisableUserContainer">
        {selectedList.length !== 0 && (
          <>
            <p className="SelectedCountDiv">{selectedList.length} selected</p>
            <div className="usersNavBtnsCont">
              <button className="usersSelectAllBtn" onClick={selectAll}>
                Select all
              </button>
              <button className="usersDeselectAllBtn" onClick={deselectAll}>
                Deselect all
              </button>
              <button className="usersDisableBtn" onClick={() => setShowDisablePopUp(true)}>
                <img src={DisableIcon} alt="Disable" />
                Disable
              </button>
            </div>
          </>
        )}
      </div>

      <Modal
        show={showDisablePopUp}
        onHide={() => setShowDisablePopUp(false)}
        centered
        size="md"
        backdrop="static"
        className="createUserPopupModal"
      >
        <div className="DisablePopUp">
          <div className="DisablePopUpHeader">
            <div className="DisableUserHeaderContent">Disable Users?</div>
            <div className="DisableUserHeaderClose" onClick={() => setShowDisablePopUp(false)}>
              <img className="iconCloseImg" src={IconClose} alt="Close"/>
            </div>
          </div>
          <div className="DisablePopUpBody">
            This action will disable the selected users. Do you want to proceed with this bulk action?
          </div>
          <div className="DisablePopUpFooter">
            <button className="CancelButton" onClick={() => setShowDisablePopUp(false)}>Cancel</button>
            <button className="DisableButton" onClick={handleBulkAction}>Proceed</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DisableUser;
