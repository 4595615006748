import React, {  useState} from "react";
import AddIcon from "../../assets/icons/AddIcon.svg";
import "./Users.scss";
import UsersCardContainer from "./usersCardContainer/UsersCardContainer";
import { CreateUser } from "./createUser/CreateUser";
function Users() {
  const [setAddUser, setAddUserPopup] = useState(false);
  return (
    <div className="wholeUserCardContainer">
      <div className="usersFilterBtnCont">
        <div className="usersBtnsConteiner">
          <button className="addUserBtn" onClick={() => setAddUserPopup(true)}>
            <img src={AddIcon} alt="icon" />
            Add user
          </button>
        </div>
      </div>
        <UsersCardContainer />
        {setAddUser && <CreateUser setAddUserPopup={setAddUserPopup}/>}
    </div>
  );
}
export default Users;