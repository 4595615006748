import { Link } from "react-router-dom";
import CostIcon from "../../assets/icons/icon-estimated-cost.svg";
import EnergyConsumptionIcon from "../../assets/icons/energyConsumptionIcon.svg";
import EnergyIcon from "../../assets/icons/energyIcon.svg";
import waterConsumedIcon from '../../assets/icons/water-consumed-icon.svg';
import airConsumedIcon from '../../assets/icons/air-consumed-icon.svg';
import gasConsumedIcon from '../../assets/icons/gas-consumed-icon.svg';
import fuelConsumedIcon from '../../assets/icons/fuel-consumed-icon.svg';
import EstimatedCostIcon from "../../assets/icons/graph-cost-icon.svg";
import ConsumptionSqftIcon from '../../assets/icons/icon-consumption-sqft.svg'
import FilterIcon from "../../assets/icons/filterArrowIcon.svg";
import "./SingleFacilityEnergyConsumption.scss";
import MeterCard from "./meterCard/MeterCard";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import { useStateValue } from "../../redux/StateProvider";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import ApparentPowerIcon from "../../assets/icons/avgApparentPowerIcon.svg";
import AvgConsumptionIcon from "../../assets/icons/avgConsumptionIcon.svg";
import AvgPowerFactorIcon from "../../assets/icons/avgPowerFactorIcon.svg";
import ReactEcharts from "echarts-for-react";
import LoaderStyle from "../LoaderStyle/LoaderStyle";
import DisplayError from "../DisplayError/DisplayError";
import AverageReactPowerIcon from '../../assets/icons/AverageReactivePowerEC.svg'
import energyAssetTypeIcon from '../../assets/icons/ennergyAssetTypeIcon.svg';
import leftAlignedAssetIcon from '../../assets/icons/leftAlignedAssetTypeICon.svg';
import activeEnergyAssetTypeICon from '../../assets/icons/ActiveEnergyAssetTypeIcon.svg';
import energyTypeCloseICon from '../../assets/icons/energyTypeCloseIcon.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EnergyActiveIcon from "../../assets/icons/energyActiveIcon.svg"
import AirActiveIcon from "../../assets/icons/airActiveIcon.svg"
import FuelActiveIcon from "../../assets/icons/fuelActiveIcon.svg"
import GasActiveIcon from "../../assets/icons/gasActiveIcon.svg"
import WaterActiveIcon from "../../assets/icons/waterActiveIcon.svg"
import EnergyInActiveIcon from "../../assets/icons/energyInActiveIcon.svg"
import AirInActiveIcon from "../../assets/icons/airInActiveIcon.svg"
import FuelInActiveIcon from "../../assets/icons/fuelInActiveIcon.svg"
import GasInaActiveIcon from "../../assets/icons/gasInActiveIcon.svg"
import WaterInActiveIcon from "../../assets/icons/waterInActiveIcon.svg"
import ShiftWiseIcon from "../../assets/icons/shiftWiseIcon.svg"
import ShiftGraphIcon from '../../assets/icons/ShiftWise-Graph-Icon.svg'
import AvgLoadIcon from '../../assets/icons/icon-metrics-load.svg'

import airMeterIcon from "../../assets/icons/air-main-meter-icon.svg"
import airSubMeterIcon from "../../assets/icons/air-sub-meter-icon.svg"
import waterMeterIcon from "../../assets/icons/water-main-meter-icon.svg"
import waterSubMeterIcon from "../../assets/icons/water-sub-meter-icon.svg"
import gasMeterIcon from "../../assets/icons/gas-main-meter-icon.svg"
import gasSubMeterIcon from "../../assets/icons/gas-sub-meter-icon.svg"
import fuelMeterIcon from "../../assets/icons/fuel-main-meter-icon.svg"
import fuelSubMeterIcon from "../../assets/icons/fuel-sub-meter-icon.svg"
import { API_BASE_URL } from "../../Constants/constants";
import { formatNumberData, roundToDecimalNumber } from "../../Utils/utils";
import MainMeterToggleIcon from './Assets/MainMeterToggleIcon.svg'
import SubMeterToggleIcon from './Assets/SubMeterToggleIcon.svg'
import FacilityCardIcon from './Assets/facilityCardIcon.svg'
import seeMoreIcon from './Assets/see-more.svg'
import seeLessIcon from './Assets/seeless.svg'
import ThreeRowsScrollableDiv from "./scrollableCard/ThreeRowsScrollableDiv";
import rightScrollIcon from './Assets/rightScroll.png';

function FuelCardComponent(props: any) {

  const {mainMeterSubMeterPermissions, fullSizedComponents, decimalVal, currentFilterByTypeAndId, shiftFlag } = props;
  const mainCallTypeRef = useRef('Main');
  useEffect(() => {
    setAPICallType('Main');
    mainCallTypeRef.current = 'Main';
    setMainRespStat('load');
    setActiveAssetType('');
    setActiveMeter("");
    setShiftId("")
    setMouseHoverCard("")
    setOpacityVal(1)
    setAssetTypeRespStat('resp')
    // setSingleFacilityData([]);
  }, [currentFilterByTypeAndId])

  const [tabsArr, setTabsArr] = useState([{ name: 'Energy', abbreviation: 'L', id: 0, activeIcon: EnergyActiveIcon, inActiveIcon: EnergyInActiveIcon, consumedIcon: EnergyIcon, meterIcon: '', subMeterIcon: '' },
  { name: 'Air', abbreviation: 'm3', id: 1, activeIcon: AirActiveIcon, inActiveIcon: AirInActiveIcon, consumedIcon: airConsumedIcon, meterIcon: airMeterIcon, subMeterIcon: airSubMeterIcon },
  { name: 'Water', abbreviation: 'KL', id: 2, activeIcon: WaterActiveIcon, inActiveIcon: WaterInActiveIcon, consumedIcon: waterConsumedIcon, meterIcon: waterMeterIcon, subMeterIcon: waterSubMeterIcon },
  { name: "Gas", abbreviation: '', id: 3, activeIcon: GasActiveIcon, inActiveIcon: GasInaActiveIcon, consumedIcon: gasConsumedIcon, meterIcon: gasMeterIcon, subMeterIcon: gasSubMeterIcon },
  { name: 'Fuel', abbreviation: '', id: 4, activeIcon: FuelActiveIcon, inActiveIcon: FuelInActiveIcon, consumedIcon: fuelConsumedIcon, meterIcon: fuelMeterIcon, subMeterIcon: fuelSubMeterIcon }])


  const [singleFacilityData, setSingleFacilityData] = useState<any>([]);
  const [{ dateRange, dateChip }, dispatch] = useStateValue();
  const [chartOptions, setChartOptions] = useState({});
  const [meterOptions, setMeterOptions] = useState({});
  const [activeMeter, setActiveMeter] = useState("");
  const [meterAssetData, setMeterAssetData] = useState<any>({
    averageConsumptionPerDay: '',
    abbreviation: '', powerFactor: ''
  });
  const [apiCallType, setAPICallType] = useState("Main");
  const [mainRespStat, setMainRespStat] = useState("load");
  const [dropDownRespStat, setDropdownRespStat] = useState("resp");
  const [assetTypeRespStat, setAssetTypeRespStat] = useState("resp");

  const [showLatestDecimalValue, setLatestDecimalValue] = useState(2);
  const [storeChartData, setStoreChartData] = useState<any>({});
  useEffect(()=>{
    if(storeChartData.trendSummary !== undefined){
      formatChartData(storeChartData)
      setLatestDecimalValue(decimalVal)
    }
      setLatestDecimalValue(decimalVal)
  },[decimalVal, storeChartData])

  const [ productMeterTypeId , setProductMeterId ] = useState('');

  const formatChartData = (data: any) => {
    setDropdownRespStat('resp');
    if(data?.trendSummary == undefined){
      setDropdownRespStat('empty');
      return;
    }
    const xAxisData = [];
    const energyConsumptionData: any = [];
    const estimatedCostData: any = [];
    let defaultUom = 'L';
    for (let x of Object.values(data?.trendSummary)) {
      if (x[0].sum !== null) {
        setProductMeterId(x[0].type);
        xAxisData.push(x[0].dateValue);
        defaultUom = x[0]?.defaultUom;
        energyConsumptionData.push(roundToDecimalNumber(x[0].sum, showLatestDecimalValue))
      }
    }

    setChartOptions({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        },
        formatter: function (params) {
          let tooltipText = params[0].axisValueLabel + '<br>';
          params.forEach(param => {
            tooltipText += `${param.marker} ${param.seriesName}: ${formatNumberData(param.data, 2)}<br>`;
          });
          return tooltipText;
        }
        // formatter: function (params) {
        //   var tooltip = '<div>' + `${storeXAxisFullDates[params[0].axisValue].startDate} - ${storeXAxisFullDates[params[0].axisValue].endDate}` + '</div>';
        //   params.forEach(function (item) {
        //     tooltip += `<div> <span style="color:${item.seriesName === 'L' ? 'blue' : 'green'}">&#9679;</span> ` + `${item.seriesName === 'L' ? 'Consumption' : item.seriesName}` + '  ' + `${item.seriesName === 'L' ? `${item.value} L` : `INR ${item.value}`}` + '</div>';
        //   });
        //   return tooltip;
        // }
      },
      // legend: {
      //   data: ['L', 'Estimated Cost']
      // },
      xAxis: [
        {
          type: 'category',
          data: xAxisData,
          axisPointer: {
            type: 'shadow'
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed"
            }
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: defaultUom,
          positon: 'right',
          // min: (Math.min(...energyConsumptionData) * 1.0).toFixed(2),
          // max: (Math.max(...energyConsumptionData) * 1.0).toFixed(2),
          nameRotate: 90,
          nameGap: 70,
          nameLocation: 'middle',
          axisLabel: {
            formatter: (value:any) => formatNumberData(value, showLatestDecimalValue)
          }
        },
        {
          type: 'value',
          name: 'Estimated Cost',
          positon: 'left',
          // min: (Math.min(...estimatedCostData) * 1.0).toFixed(2),
          // max: (Math.max(...estimatedCostData) * 1.0).toFixed(2),
          nameRotate: 90,
          nameGap: 79,
          nameLocation: 'middle',
          axisLabel: {
            formatter: (value:any) => formatNumberData(value, showLatestDecimalValue)
          }
        }
      ],
      series: [
        {
          name: defaultUom,
          type: 'bar',
          data: energyConsumptionData,
          itemStyle: {
            normal: {
              color: activeShiftId !== '' ? 'rgba(239, 161, 129, 1)' : '#0E9CFF'
            }
          }
        },
        {
          name: 'Estimated Cost',
          type: 'line',
          yAxisIndex: 1,
          data: estimatedCostData
        }
      ],
      grid: {
        left: 50,
        right: '5%',
        bottom: '1%',
        top: '2%',
        containLabel: true,
      },
    })

  };

  const [activeShiftId, setShiftId] = useState('');
  const [opacityVal, setOpacityVal] = useState(1);
  const setActiveShiftId = (shiftId: any) => {
    if (activeShiftId === shiftId) {
      setShiftId("");
      setOpacityVal(1);
      return;
    }
    setShiftId(shiftId);
    setOpacityVal(0.4)
  }
  const changeMeterId = (meterId: any) => {
    if (meterId === activeMeter) {
      setActiveMeter("");
      setShiftId("")
      setOpacityVal(1)
      setAPICallType("AssetType"); setAssetTypeRespStat('load')
      mainCallTypeRef.current = ''
      return;
    }
    setShiftId("")
    setOpacityVal(1)
    setActiveMeter(meterId);
    mainCallTypeRef.current = ''
    setAPICallType("AssetType"); setAssetTypeRespStat('load')
  };

  type ChartsDropDownData = {
    [key: string]: string[];
  };
  const chartsDropDownData: ChartsDropDownData = {
    Today: [
      "Last 7 days",
      "Last 14 Days",
      "Last 30 Days",
      "Last 60 Days",
      "Last 90 Days",
    ],
    "This Week": ["Last 4 Weeks", "Last 8 Weeks", "Last 12 Weeks"],
    "This Month": [
      "Last 3 Months",
      "Last 6 Months",
      "Last 9 Months",
      "Last 12 Months",
    ],
    customDate: ["Custom Range"],
  };
  const [dropDownDisplayType, setDropDownDisplayType] = useState<string>("");
  const [dropDownList, setDropDownList] = useState([""]);
  const [showDropDown, showDropDownList] = useState(false);
  useEffect(() => {
    // Setting idle state when date changes..
    setAPICallType("Main");
    mainCallTypeRef.current = 'Main'
    setMainRespStat('load');
    setActiveAssetType('');
    setActiveMeter("");
    setShiftId("");
    setAssetIdsList([]);
    setMouseHoverCard("");
    setOpacityVal(1)
    if (Object.keys(chartsDropDownData).includes(dateChip)) {
      setDropDownDisplayType(chartsDropDownData[dateChip][0]);
      setDropDownList(chartsDropDownData[dateChip]);
      return;
    }else{
    setDropDownDisplayType(chartsDropDownData["customDate"][0]);
    setDropDownList(chartsDropDownData["customDate"]);
  }
  }, [dateRange, dateChip, shiftFlag]);

  // const [energyAssetTypeArr, setEnergyAssetTypeArr] = useState<any>([])
  // const [shiftWiseData, setShiftWiseData] = useState<any>({})
  const [assetMetersList, setAssetMetersList] = useState<any>([])
  const [assetIdsList, setAssetIdsList] = useState<any>([]);

  useEffect(()=>{
    if(mainMeterSubMeterPermissions?.length === 2){
      setActiveMeterToggle('CNSP_MAIN_ENABLED');
    }else if(mainMeterSubMeterPermissions[0] === 'CNSP_MAIN_ENABLED' ){
      setActiveMeterToggle('CNSP_MAIN_ENABLED');
    }else{
      setActiveMeterToggle('CNSP_SUB_ENABLED');
    }
    },[mainMeterSubMeterPermissions])

    const [activeMeterToggle ,setActiveMeterToggle] = useState('');
    const [facilityDataForMainMeters, setFacilityDataForMainMeters] = useState([]);
    const [activeFacilityId, setActiveFacilityId] = useState('');

    const setFacilityId = (id:any) =>{
      setActiveFacilityId(id)

    }

  const getSingleFacilityData = async (
    date: any,
    type: string,
    facId: string,
    dateType: string,
    range: any,
    abortController: any
  ) => {
    setStoreChartData({});
    let startDate = moment(date[0], "YYYY-MM-DD HH:mm:ss").valueOf();
    let endDate = moment(date[1], "YYYY-MM-DD HH:mm:ss").valueOf();
    const token: any = localStorage.getItem("@tokenID");
    const options = {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: abortController.signal
    };
    const params : any= {
      options: options,
      startDate: startDate,
      endDate: endDate,
      facilityType: currentFilterByTypeAndId.filterByType,
      filterById: currentFilterByTypeAndId.filterById,
      dateType: dateType,
      productMeterTypeId: 'Wh_EB',
      consumptionType: 'FUEL',
      pageIndex: 1,
      pageSize: 100,
      fixedAssetTypeId: activeAssetTypeId,
      fixedAssetId: (activeAssetTypeId === "" && activeFacilityId === '') ? "" : activeMeter,
      fetchShiftData : shiftFlag
  };
  if(activeMeterToggle !== ''){
    params.isSubMeterConsp = activeMeterToggle === 'CNSP_SUB_ENABLED' ? true : false;
  }
  if(activeFacilityId !== '' &&  activeMeterToggle !== 'CNSP_SUB_ENABLED'){
    params.facilityIdForConsp = activeFacilityId;
  }

  if (dateChip !== 'Custom') {
      params.range = range;
  }
    if (apiCallType == "Main") {
      setMainRespStat("load");
    } else if (apiCallType === "Sub") {
      setDropdownRespStat("load");
    }
    const singleFacilityManagerResp = await new DashBoardServices().getSingleFacilityManagerData(params);

    if (apiCallType == "Main" || mainCallTypeRef.current == 'Main') {
      if (singleFacilityManagerResp == "Error") {
        setMainRespStat("error");
        return;
      } else if (singleFacilityManagerResp == 'cancelled') {
        setMainRespStat("load");
        return;
      }
      else if (
        singleFacilityManagerResp?.consumptionSummary?.overAllData?.length == 0 && singleFacilityManagerResp?.consumptionSummary?.assetTypeData?.length == 0
      ) {
        setMainRespStat("empty");
      } else {
        setMainRespStat("resp");
        setAssetTypeRespStat("resp");
      }
    } else if (apiCallType === "Sub") {

      if (singleFacilityManagerResp == "Error") {
        setDropdownRespStat("load");
        return;
      } else if (singleFacilityManagerResp == 'cancelled') {
        setDropdownRespStat("load");
      }
      else if (
        singleFacilityManagerResp?.consumptionSummary?.overAllData?.length == 0 && singleFacilityManagerResp?.consumptionSummary?.assetData?.length == 0
      ) {
        setDropdownRespStat("empty");
      } else {
        setDropdownRespStat("resp");
      }
    }
    else {

      if (singleFacilityManagerResp == "Error") {
        setAssetTypeRespStat("error");
        return;
      } else if (singleFacilityManagerResp == 'cancelled') {
        setAssetTypeRespStat("load");
        return;
      }
      else if (
        singleFacilityManagerResp?.consumptionSummary?.overAllData?.length == 0 && singleFacilityManagerResp?.consumptionSummary?.assetData?.length == 0
      ) {
        setAssetTypeRespStat("empty");
      } else {
        setAssetTypeRespStat("resp");
      }
    }

    if (singleFacilityManagerResp !== 'Error') {
      CalculateShiftWiseDataWidth(singleFacilityManagerResp?.consumptionSummary?.shiftData?.shiftConsumption?.shiftConsumption)
      setSingleFacilityData(singleFacilityManagerResp?.consumptionSummary);
      setAssetIdsList(singleFacilityManagerResp?.consumptionSummary?.assetIdsList === undefined ? []:singleFacilityManagerResp?.consumptionSummary?.assetIdsList );
      if(Object.keys(singleFacilityManagerResp?.consumptionSummary?.trendData).length === 0 || Object.keys(singleFacilityManagerResp?.consumptionSummary?.trendData?.trendSummary).length === 0){
        setDropdownRespStat("empty");
      }else{
        setStoreChartData(singleFacilityManagerResp?.consumptionSummary?.trendData);
        formatChartData(singleFacilityManagerResp?.consumptionSummary?.trendData);
      }
      // setEnergyAssetTypeArr(singleFacilityManagerResp?.consumptionSummary?.assetType?.allAssetType)
      if (singleFacilityManagerResp?.consumptionSummary?.assetTypeData?.length == 1 ) {
        setAssetMetersList(singleFacilityManagerResp?.consumptionSummary?.assetData)
      }else if(singleFacilityManagerResp?.consumptionSummary?.facilityData?.length == 1 && assetMetersList.length == 0) {
        setAssetMetersList(singleFacilityManagerResp?.consumptionSummary?.assetData)
      }

      if(singleFacilityManagerResp?.consumptionSummary?.facilityData !== undefined){
        setFacilityDataForMainMeters(singleFacilityManagerResp?.consumptionSummary?.facilityData);
      }
    //   if (Object.keys(chartsDropDownData).includes(dateChip)) {
    //     setDropDownDisplayType(chartsDropDownData[dateChip][0]);
    //     return;
    //   }else{
    //   setDropDownDisplayType(chartsDropDownData["customDate"][0]);
    // }
    }
  };

  const abortController = useRef(new AbortController());
  const [activeAssetTypeId, setActiveAssetType] = useState('');
  useEffect(() => {
    const controller = new AbortController();
    if (dropDownDisplayType !== "") {
      const prevController = abortController.current;
      abortController.current = controller;

      // Cancel the previous request
      prevController.abort();
      const dateType =
        dateChip === "Today"
          ? "day"
          : dateChip === "This Week"
            ? "week"
            : dateChip === "This Month"
              ? "month"
              : false;
      if (dateType) {
        let startDateMilliSeconds = dropDownDisplayType.split(" ");
        if (dropDownDisplayType === 'Hourly') {
          getSingleFacilityData(dateRange, "facility", 'firstFacilityId', 'hour', 5, controller);
          return;
        }
        getSingleFacilityData(dateRange, "facility", 'firstFacilityId', dateType, +startDateMilliSeconds[1], controller);
        return;
      } else {
        getSingleFacilityData(dateRange, "facility", 'firstFacilityId', "day", 0, controller);
        return;
      }
      abortController.current.abort();
      abortController.current = controller;
    }
    return () => {
      controller.abort();
    };
  }, [dropDownList, activeAssetTypeId, activeMeter, currentFilterByTypeAndId, activeMeterToggle, activeFacilityId]);

  useEffect(() => {
    const token_id = localStorage.getItem("@tokenID");
    const controller = new AbortController();
    const { signal } = controller;

    let payLoadObj: any = {
      "fetchShiftDataWithParty" : shiftFlag,
      "productMeterTypeIds": productMeterTypeId,
      "fromDate": moment(dateRange[0], "YYYY-MM-DD HH:mm:ss").valueOf(),
      "thruDate": moment(dateRange[1], "YYYY-MM-DD HH:mm:ss").valueOf(),
      "frequency": dropDownDisplayType === 'Hourly' ? 'hour' : dropDownDisplayType.split(" ")[2] === 'Months' ? 'month' : dropDownDisplayType.split(" ")[2] === 'Weeks' ? 'week' : 'day',
   }
    if(dateChip !== 'Custom'){
      payLoadObj.range =  dropDownDisplayType === 'Hourly' ? 5 : dropDownDisplayType.split(" ")[1];
    }

    if (activeShiftId !== '') {
      const shiftFlagData = singleFacilityData?.shiftData?.shiftConsumption?.shiftConsumption?.filter((obj) => obj.description === activeShiftId)[0]
    payLoadObj.shiftId = shiftFlagData?.shiftId;
  payLoadObj.facilityIdForShift = shiftFlagData?.facilityId;
     payLoadObj.fetchShiftDataWithShiftId = shiftFlag;
    }
    payLoadObj.fixedAssetIdsList = assetIdsList;
    // if (activeMeter !== '') {
    //   payLoadObj.fixedAssetId = activeMeter;
    // }
    // if (currentFilterByTypeAndId.filterById !== "_NA_") {
    //   payLoadObj.facilityId = currentFilterByTypeAndId.filterById;
    // }
    // if (activeAssetTypeId !== '') {
    //   payLoadObj.fixedAssetTypeId = activeAssetTypeId;
    // }

    const fetchMetricsAPI = async () => {
    setDropdownRespStat('load')
      try {
        const metricsDataResp = await fetch(`${API_BASE_URL}assets/metricTrendSummary`, {
          method: "POST",
          body: JSON.stringify(payLoadObj),
          headers: {
            Authorization: `Bearer ${token_id}`,
            "Content-Type": "application/json",
          },
          signal
        });

        if (!metricsDataResp.ok) {
          setDropdownRespStat('error')
          throw new Error('Network response was not ok');
        }

        const metricsData = await metricsDataResp.json();
        setStoreChartData(metricsData);
        formatChartData(metricsData)
        setDropdownRespStat('resp')
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch request was cancelled');
          setDropdownRespStat('load')
        } else {
          console.error('Fetch error:', error);
        }
      }
    };

    if(assetIdsList.length !== 0){
      fetchMetricsAPI();
    }

    return () => {
      controller.abort();
    };
  }, [dropDownDisplayType, activeShiftId]);
  // dateRange, activeShiftId, activeAssetTypeId, currentFilterByTypeAndId.filterById
  const [mouseHoverCard, setMouseHoverCard] = useState('');
  // converting strings to PascalCase
  function toPascalCase(str: string) {
    const words = str.split(' ');
    const pascalCaseWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return pascalCaseWords.join('');
  }

  const cardsImageData: any = {
    'Reactive Power': {
      title: 'Average Reactive Power',
      image: AverageReactPowerIcon
    },
    'Power Factor': {
      title: 'Average Power Factor',
      image: AvgPowerFactorIcon
    },
    consumptionPerSq: {
      title: 'Consumption Per sq. foot',
      image: ConsumptionSqftIcon
    },
    'Apparent Power': {
      title: 'Apparent Power',
      image: ApparentPowerIcon
    },
    consumptionPerFacility: {
      title: 'Average Consumption Per Facility',
      image: ApparentPowerIcon
    },
    consumptionPerDay: {
      title: 'Average Consumption Per Day',
      image: AvgConsumptionIcon
    },
    'Active Power': {
      title: 'Average Load',
      image: AvgLoadIcon
    }

  }
  const ignoredKeysArr = ['totalConsumption', 'consumptionCost', 'dateValue', 'energyConsumption', 'endDate', 'startDate', "estimatedCost", "energyUnit", "contractDemand", "Unit", "fixedAssetType", "fixedAssetTypeId", "classEnumId", "fixedAssetName", "classEnumDesc", "fixedAssetId"];
  const [shiftWiseWidthObj, setShiftWiseWidthObj] = useState<any>({})
  const overAllShiftWiseConsumption = useRef(0);

  const CalculateShiftWiseDataWidth = (data: any) => {
    // Adding width for the shift wise cards.
    const percentage = [{ className: 'shiftThreeContainer', width: '100%' }, { className: 'shiftTwoContainer', width: '75%' }, { className: 'shiftOneContainer', width: '49%' }];
    if (data !== undefined && data.length !== 0) {
      let dummyObj: any = {};
      let totalConsumption = 0
      let shallowCopyData = [...data];
      shallowCopyData.
        // filter((obj: any) => obj.consumption.value !== null).
        sort((b: any, a: any) => a.value - b.value).
        forEach((element: any, index: number) => {
          totalConsumption += element.value
          dummyObj[element.description] = percentage[index]
        });
      overAllShiftWiseConsumption.current = totalConsumption;
      setShiftWiseWidthObj(dummyObj);
      // setShiftWiseData(data);
    }
  }
  const findNullValueCheck = (value: any, uom: any, decimalType: any, cost='noCost') => {
    uom = uom == null ? '' : uom;
    let decimal = decimalType === 'common' ? 2 : decimalType

    if (value === null) {
      return '--'
    } else {
      return cost === 'cost' ? uom + " " + formatNumberData(value, decimal) : formatNumberData(value, decimal) + " " + uom;
    }
  }

  const [showMore, setShowMore] = useState(true);
  const [displaySeeMore, setDisplaySeeMore] = useState(false);

  // useEffect(()=>{
  //   let number = fullSizedComponents.includes('CONSUMPTION_V1') ? 7 : 4;
  //   if(singleFacilityData?.assetTypeData?.length >= number || singleFacilityData?.facilityData?.length >= number || assetMetersList?.length >= number){
  //     setDisplaySeeMore(true);
  //   }else{
  //     setDisplaySeeMore(false);
  //     setShowMore(true);
  //   }

  // },[singleFacilityData, assetMetersList])

 

  const scrollableContainerRef = useRef(null);
const [isAtLeft, setIsAtLeft] = useState(true); // Initially at the left
const [isAtRight, setIsAtRight] = useState(false); // Initially not at the right

const handleScroll = (
  direction: string,
  scrollableContainerRef: any,
  setIsAtLeft: any,
  setIsAtRight: any
) => {
  const checkScrollPosition = (container: any, setIsAtLeft: any, setIsAtRight: any) => {
    const isLeft = container.scrollLeft === 0;
    const isRight = Math.abs(container.scrollLeft + container.clientWidth - container.scrollWidth) <= 1; // Threshold for floating-point errors
    setIsAtLeft(isLeft);
    setIsAtRight(isRight);
  };

  if (scrollableContainerRef.current) {
    const container = scrollableContainerRef.current;
    container.scrollBy({
      left: direction === "right" ? 130 : -130,
      behavior: "smooth",
    });
    setTimeout(() => checkScrollPosition(container, setIsAtLeft, setIsAtRight), 300);
  }
};

  const checkScrollPosition = (container: any, setIsAtLeft: any, setIsAtRight: any) => {
    const isLeft = container.scrollLeft === 0;
    const isRight = Math.abs(container.scrollLeft + container.clientWidth - container.scrollWidth) <= 1; // Threshold for floating-point errors
    setIsAtLeft(isLeft);
    setIsAtRight(isRight);
  };
  
  // Initial state check
  useEffect(() => {
    if (scrollableContainerRef.current) {
      const container = scrollableContainerRef.current;
      const isLeft = container.scrollLeft === 0;
      const isRight = Math.abs(container.scrollLeft + container.clientWidth - container.scrollWidth) <= 1;
      setIsAtLeft(isLeft);
      setIsAtRight(isRight);
    }
  }, []);
  
  useEffect(()=>{
    if(!isAtRight){
    setDisplaySeeMore(true);
    }else if(!showMore){
      setDisplaySeeMore(true);
    }else{
      setDisplaySeeMore(false)
    }

    if(singleFacilityData?.assetTypeData?.length === 0 && facilityDataForMainMeters?.length === 0 ){
      setDisplaySeeMore(false)
    }

  },[isAtRight, showMore, singleFacilityData ])


  return (
    <>
      {" "}
      {mainRespStat === "load" ? (
        <div className="loader-cont">
          <LoaderStyle />
        </div>
      ) : mainRespStat === "empty" ? (
        <DisplayError type={"err-empty"} />
      ) : mainRespStat === "error" ? (
        <DisplayError type={"err-500/404"} />
      ) : (
        <>
          <div className="energyConsumptionDetailsContainer">
            <div className="energyStatsContainer">

              <div className="statsDetailsDiv">
                <p className="statsHeader">{`Fuel Consumed`}</p>
                <div className="consumptionDetails">
                  <img src={tabsArr[4].consumedIcon} alt="icon" className="statsImage" />
                  <h4 className="consumptionCount">  {" "}
                    {
                      singleFacilityData?.overAllData?.filter((obj: any) => obj.hasOwnProperty('totalConsumption')).length !== 0 ?
                      findNullValueCheck(singleFacilityData?.overAllData?.filter((obj: any) => obj.hasOwnProperty('totalConsumption'))
                      [0].totalConsumption.value, singleFacilityData?.overAllData?.filter((obj: any) => obj.hasOwnProperty('totalConsumption'))
                      [0].totalConsumption.defaultUom, showLatestDecimalValue): '--'
                    }
                  </h4>
                </div>
              </div>
              <div className="borderContainer"></div>

              {<div className="statsDetailsDiv">
                <p className="statsHeader">Estimated Cost</p>
                <div className="consumptionDetails">
                  <img src={CostIcon} alt="icon" className="statsImage" />
                  {/* {activeAssetTypeId === "" && activeShiftId === '' && */}
                  <h4 className="consumptionCount"> {" "}
                    {
                      singleFacilityData?.overAllData?.filter((obj: any) => obj.hasOwnProperty('consumptionCost')).length !== 0 ?
                      findNullValueCheck(singleFacilityData?.overAllData?.filter((obj: any) => obj.hasOwnProperty('consumptionCost'))[0].consumptionCost.value,
                      singleFacilityData?.overAllData?.filter((obj: any) => obj.hasOwnProperty('consumptionCost'))[0].consumptionCost.defaultUom,
                      showLatestDecimalValue,'cost'
                    ): '--'
                    }
                  </h4>
                </div>
              </div>}
            </div>

            {
              mainMeterSubMeterPermissions?.length === 2 ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="mainMeterSubMeterToggle">
                      <div onClick={() => {
                        if (activeMeterToggle !== 'CNSP_MAIN_ENABLED')
                        {
                          setActiveMeterToggle('CNSP_MAIN_ENABLED');
                          setAssetMetersList([])
                          setActiveAssetType('');
                          setAPICallType("AssetType");
                          mainCallTypeRef.current = '';
                          changeMeterId(activeMeter);
                          // setAssetTypeRespStat('load');
                          setShiftId("");
                          setOpacityVal(1);}
                      }} style={{ backgroundColor: activeMeterToggle === 'CNSP_MAIN_ENABLED' ? 'white' : '', border: activeMeterToggle === 'CNSP_MAIN_ENABLED' ? '1px solid rgb(251, 217, 217)' : '1px solid transparent' }}>
                        <img src={MainMeterToggleIcon} alt="MeterIcon" />
                        <p>Main Meters</p>
                      </div>
                      <div onClick={() => {
                        if (activeMeterToggle !== 'CNSP_SUB_ENABLED')
                        {
                          setFacilityId('');
                          setAssetMetersList([]);
                          setActiveMeterToggle('CNSP_SUB_ENABLED')
                          setAPICallType("AssetType");
                          mainCallTypeRef.current = '';
                          changeMeterId(activeMeter);
                          // setAssetTypeRespStat('load');
                          setShiftId("");
                          setOpacityVal(1);}
                      }} style={{ backgroundColor: activeMeterToggle === 'CNSP_SUB_ENABLED' ? 'white' : '', border: activeMeterToggle === 'CNSP_SUB_ENABLED' ? '1px solid rgb(251, 217, 217)' : '1px solid transparent' }}>
                        <img src={SubMeterToggleIcon} alt="SubMeterIcon" />
                        <p>Sub Meters</p>
                      </div>
                    </div>
                    {displaySeeMore && <img onClick={() => setShowMore(!showMore)} style={{ alignSelf: 'end', cursor: 'pointer' }} src={showMore ? seeMoreIcon : seeLessIcon} alt="seeMore" />}
                  </div>
                  : mainMeterSubMeterPermissions[0] === 'CNSP_MAIN_ENABLED' ?
                      <div style={{ display: 'flex' }}>
                        <div className="mainMeterConsumptionContainer">
                          <img src={MainMeterToggleIcon} alt="icon" />
                          <p>Main Meter Consumption</p>
                          <p className="mainMeterToggleBorderText">Facility-wise</p>
                        </div>
                        {displaySeeMore && <img onClick={() => setShowMore(!showMore)} style={{ alignSelf: 'end', cursor: 'pointer' }} src={showMore ? seeMoreIcon : seeLessIcon} alt="seeMore" />}
                      </div> : <div style={{ display: 'flex' }}>
                        <div className="mainMeterConsumptionContainer">
                          <img src={SubMeterToggleIcon} alt="icon" />
                          <p>Sub Meter Consumption</p>
                          <p className="mainMeterToggleBorderText">Asset type-wise</p>
                        </div>
                        {displaySeeMore && <img onClick={() => setShowMore(!showMore)} style={{ alignSelf: 'end', cursor: 'pointer' }} src={showMore ? seeMoreIcon : seeLessIcon} alt="seeMore" />}
                      </div>
            }

            <div className="subMetersCardsContainer">
              {/* Active Asset Type Chip will render here.. */}
              {activeAssetTypeId !== '' &&
                <div className="energyActiveAssetTypeChip" onClick={() => { setActiveAssetType(''); setAPICallType("AssetType"); mainCallTypeRef.current = ''; changeMeterId(activeMeter); setAssetTypeRespStat('load'); setShiftId(""); setOpacityVal(1); setAssetMetersList([]) }}>
                  <p>{singleFacilityData && singleFacilityData?.assetTypeData?.length !== 0 && singleFacilityData?.assetTypeData?.find((obj: any) => obj.fixedAssetTypeId === activeAssetTypeId)?.fixedAssetType}</p>
                  <img src={energyTypeCloseICon} alt="closeIcon" />
                </div>}

                {/* Active Facility Chip will render here... */}

                {activeFacilityId !== '' &&
                <div className="energyActiveAssetTypeChip"
                 onClick={() =>
                 { setFacilityId('');
                  setAPICallType("AssetType");
                  mainCallTypeRef.current = '';
                  changeMeterId(activeMeter);
                  setAssetTypeRespStat('load');
                  setShiftId("");
                  setOpacityVal(1);
                  setAssetMetersList([])
                  }}>
                  <p>{singleFacilityData && facilityDataForMainMeters?.length !== 0 && facilityDataForMainMeters?.find((obj: any) => obj.facilityId === activeFacilityId)?.facilityName}</p>
                  <img src={energyTypeCloseICon} alt="closeIcon" />
                </div>}

              {/* Asset Types Renders here */}

              {activeAssetTypeId === '' && activeMeterToggle !== 'CNSP_MAIN_ENABLED' && singleFacilityData?.assetTypeData?.length !== 0 &&
                      <div style={{ width: '100%', position: 'relative' }} >

                        {!isAtLeft && showMore && <button className="assetTypeScrollLeftButton" onClick={() => handleScroll('left', scrollableContainerRef, setIsAtLeft, setIsAtRight)} >
                          <img src={rightScrollIcon} alt='' />
                        </button>}

                        <div ref={scrollableContainerRef}
                          onScroll={() => checkScrollPosition(scrollableContainerRef.current, setIsAtLeft, setIsAtRight)}
                          className='energyAssetTypeCont'>

                          {
                            showMore ? singleFacilityData && singleFacilityData?.assetTypeData?.length !== 0 &&

                              singleFacilityData?.assetTypeData?.map((obj: any) =>
                                <div key={obj?.fixedAssetTypeId} className="energyAssetTypeCard"
                                  onClick={() => {
                                    setActiveAssetType(obj?.fixedAssetTypeId);
                                    setAPICallType("AssetType");
                                    mainCallTypeRef.current = '';
                                    setAssetTypeRespStat('load');
                                    setShiftId("");
                                    setOpacityVal(1)
                                  }}
                                  onMouseOver={() => setMouseHoverCard(obj?.fixedAssetTypeId)}
                                  onMouseLeave={() => setMouseHoverCard('')} >
                                  <img src={obj.fixedAssetTypeId === mouseHoverCard ? activeEnergyAssetTypeICon : energyAssetTypeIcon} alt="icon" />
                                  <div className="assetTypeInfoCont">
                                    <div>
                                      <p className="energyAssetTypeDesc">{obj?.fixedAssetType}</p>
                                      <p className="energyAssetTypevalue">{obj?.consumptionData?.length == 0 ? '--' : findNullValueCheck(obj?.consumptionData[0].value, obj?.consumptionData[0].defaultUom, showLatestDecimalValue)}</p>
                                    </div>
                                    <img src={leftAlignedAssetIcon} alt='icon' />
                                  </div>
                                </div>) : <ThreeRowsScrollableDiv
                              metersCard={'AssetType'}
                              mouseHoverCard={mouseHoverCard}
                              setMouseHoverCard={setMouseHoverCard}
                              setActiveAssetType={setActiveAssetType}
                              setAPICallType={setAPICallType}
                              mainCallTypeRef={mainCallTypeRef}
                              setAssetTypeRespStat={setAssetTypeRespStat}
                              setShiftId={setShiftId}
                              setOpacityVal={setOpacityVal}
                              showLatestDecimalValue={showLatestDecimalValue}
                              facilityDataForMainMeters={singleFacilityData?.assetTypeData} />
                          }
                        </div>

                        {!isAtRight && showMore && <button className="assetTypeScrollRightButton"
                          onClick={() => handleScroll('right', scrollableContainerRef, setIsAtLeft, setIsAtRight)}>
                          <img src={rightScrollIcon} alt='' />
                        </button>}
                      </div>}
              {/*  */}

                 {/* For Main Meters Facilities Data will render here.. */}

                 {activeMeterToggle === 'CNSP_MAIN_ENABLED' && activeFacilityId === '' && singleFacilityData && facilityDataForMainMeters?.length !== 0 &&
                      <div style={{ width: '100%', position: 'relative' }} >
                        {!isAtLeft && showMore && <button className="assetTypeScrollLeftButton" onClick={() => handleScroll('left', scrollableContainerRef, setIsAtLeft, setIsAtRight)} >
                          <img src={rightScrollIcon} alt='' />
                        </button>}

                        <div className='energyAssetTypeCont'
                          ref={scrollableContainerRef}
                          onScroll={() => checkScrollPosition(scrollableContainerRef.current, setIsAtLeft, setIsAtRight)}>
                          {
                            showMore ? singleFacilityData && facilityDataForMainMeters?.length !== 0 &&
                                facilityDataForMainMeters?.map((obj: any) =>
                                  <div key={obj.facilityId} className="facilityCards"
                                    onClick={() => {
                                      setFacilityId(obj.facilityId)
                                      //  setActiveAssetType(obj.facilityId);
                                      setAPICallType("AssetType"); mainCallTypeRef.current = '';
                                      setAssetTypeRespStat('load');
                                      setShiftId("");
                                      setOpacityVal(1)
                                    }} >
                                    <img src={FacilityCardIcon} alt="icon" />
                                    <p className="facilityTitle">{obj?.facilityName}</p>
                                    <p className="facilityValue">{obj?.consumptionData?.length === 0 ? '--' : findNullValueCheck(obj?.consumptionData[0].value, obj?.consumptionData[0].defaultUom, showLatestDecimalValue)}</p>
                                  </div>
                              ) : singleFacilityData && facilityDataForMainMeters?.length !== 0 &&
                            <ThreeRowsScrollableDiv
                              metersCard={'NotMeter'}
                              setFacilityId={setFacilityId}
                              setAPICallType={setAPICallType}
                              mainCallTypeRef={mainCallTypeRef}
                              setAssetTypeRespStat={setAssetTypeRespStat}
                              setShiftId={setShiftId}
                              setOpacityVal={setOpacityVal}
                              showLatestDecimalValue={showLatestDecimalValue}
                              facilityDataForMainMeters={facilityDataForMainMeters} />
                          }
                        </div>

                        {!isAtRight && showMore && <button className="assetTypeScrollRightButton"
                          onClick={() => handleScroll('right', scrollableContainerRef, setIsAtLeft, setIsAtRight)}>
                          <img src={rightScrollIcon} alt='' />
                        </button>}

                      </div>
                    }

              {/*Sub-Meters renders here  */}
                    {activeAssetTypeId !== '' && assetMetersList.length !== 0 &&
                      <div style={{ width: '100%', position: 'relative' }} >

                        {!isAtLeft && showMore && <button className="assetTypeScrollLeftButton" onClick={() => handleScroll('left', scrollableContainerRef, setIsAtLeft, setIsAtRight)} >
                          <img src={rightScrollIcon} alt='' />
                        </button>}

                        <div
                          ref={scrollableContainerRef}
                          onScroll={() => checkScrollPosition(scrollableContainerRef.current, setIsAtLeft, setIsAtRight)}
                          className="metricCardScrollBtnsCont">
                          {
                            showMore ?
                              assetMetersList.map((obj: any, index: any) => (
                                <MeterCard
                                  mainMeter={false}
                                  decimalVal={showLatestDecimalValue}
                                  tabsArr={tabsArr}
                                  activeTab={4}
                                  active={activeMeter === obj.fixedAssetId}
                                  changeMeterId={changeMeterId}
                                  consumption={obj?.consumptionData?.length !== 0 ? obj?.consumptionData[0].value : null}
                                  abbreviation={obj?.consumptionData?.length !== 0 ? obj?.consumptionData[0].defaultUom : null}
                                  key={obj.fixedAssetId}
                                  data={obj}
                                />
                              )) : <ThreeRowsScrollableDiv
                                metersCard={'meters'}
                                mainMeter={false}
                                decimalVal={showLatestDecimalValue}
                                tabsArr={tabsArr}
                                activeTab={4}
                                activeMeter={activeMeter}
                                changeMeterId={changeMeterId}
                                facilityDataForMainMeters={assetMetersList} />
                          }
                        </div>

                        {!isAtRight && showMore && <button className="assetTypeScrollRightButton"
                          onClick={() => handleScroll('right', scrollableContainerRef, setIsAtLeft, setIsAtRight)}>
                          <img src={rightScrollIcon} alt='' />
                        </button>}

                      </div>
                    }

              {/* Main-Meters Renders Here.... */}

                    {activeFacilityId !== '' && assetMetersList.length !== 0 &&
                      <div style={{ width: '100%', position: 'relative' }} >

                        {!isAtLeft && showMore && <button className="assetTypeScrollLeftButton" onClick={() => handleScroll('left', scrollableContainerRef, setIsAtLeft, setIsAtRight)} >
                          <img src={rightScrollIcon} alt='' />
                        </button>}

                        <div
                          ref={scrollableContainerRef}
                          onScroll={() => checkScrollPosition(scrollableContainerRef.current, setIsAtLeft, setIsAtRight)}
                          className="metricCardScrollBtnsCont">
                          {
                            showMore ? assetMetersList.map((obj: any, index: any) => (
                              <MeterCard
                                mainMeter={true}
                                decimalVal={showLatestDecimalValue}
                                tabsArr={tabsArr}
                                activeTab={4}
                                active={activeMeter === obj.fixedAssetId}
                                changeMeterId={changeMeterId}
                                consumption={obj?.consumptionData?.length !== 0 ? obj?.consumptionData[0].value : null}
                                abbreviation={obj?.consumptionData?.length !== 0 ? obj?.consumptionData[0].defaultUom : null}
                                key={obj.fixedAssetId}
                                data={obj}
                              />
                            ))


                              : <ThreeRowsScrollableDiv
                                metersCard={'meters'}
                                mainMeter={true}
                                decimalVal={showLatestDecimalValue}
                                tabsArr={tabsArr}
                                activeTab={4}
                                activeMeter={activeMeter}
                                changeMeterId={changeMeterId}
                                facilityDataForMainMeters={assetMetersList} />
                          }

                        </div>

                        {!isAtRight && showMore && <button className="assetTypeScrollRightButton"
                          onClick={() => handleScroll('right', scrollableContainerRef, setIsAtLeft, setIsAtRight)}>
                          <img src={rightScrollIcon} alt='' />
                        </button>}

                      </div>
                    }

              {assetTypeRespStat === "load" ? (
                <div className="loader-cont">
                  <LoaderStyle />
                </div>
              ) : assetTypeRespStat === "empty" ? (
                <DisplayError type={"err-empty"} />
              ) : assetTypeRespStat === "error" ? (
                <DisplayError type={"err-500/404"} />
              ) : ("")}

              {/* Cards Container renders here */}

              {assetTypeRespStat === "resp" && <div
              style={{ marginTop : activeMeterToggle === 'CNSP_MAIN_ENABLED' &&
                facilityDataForMainMeters?.length !== 0 ? '' :
                activeMeterToggle !== 'CNSP_MAIN_ENABLED' &&
                singleFacilityData?.assetTypeData?.length !== 0  ? '' : '10px'}}
               className="energyConsumptionsCardsCont">

                {
                  activeAssetTypeId === '' && activeFacilityId === '' && singleFacilityData?.overAllData &&
                  singleFacilityData?.overAllData?.map((item:any) => Object.keys(item)[0]).
                    filter((key: any) => !ignoredKeysArr.includes(key))
                    .filter((key: any) => singleFacilityData?.overAllData?.find((obj: any) => obj[key]).value !== null)
                    .map((obj: any) =>
                      <div key={obj}
                        className="consumptionsCard">
                        <p className="cardHeader">{cardsImageData[obj]?.title}</p>
                        <div className="energyConsumptionReadingCont">
                          <img src={cardsImageData[obj].image} alt="icon" />
                          <p className={obj === 'apparentPower' ? 'apparentText' : 'subMetersText'}>
                            {
                              cardsImageData[obj]?.title.includes('Consumption') ? findNullValueCheck(singleFacilityData?.overAllData?.find((elem: any) => elem[obj])[obj].value,
                              singleFacilityData?.overAllData?.find((elem: any) => elem[obj])[obj].defaultUom, showLatestDecimalValue) :
                              findNullValueCheck(singleFacilityData?.overAllData?.find((elem: any) => elem[obj])[obj].value,
                              singleFacilityData?.overAllData?.find((elem: any) => elem[obj])[obj].defaultUom, 'common')
                            }
                          </p>
                          {obj === 'apparentPower' && singleFacilityData?.overAllData?.find((elem: any) => elem[obj])[obj].value !== null && <p className="subMetersText">
                            Contract Demand{" "}
                            <span>
                              {
                                findNullValueCheck(singleFacilityData?.overAllData?.find((elem: any) => elem[obj])[obj].value,
                                singleFacilityData?.overAllData?.find((elem: any) => elem[obj])[obj].abbreviation, 'common' )
                              }
                            </span>
                          </p>}
                        </div>
                      </div>)
                }

                {
                  activeAssetTypeId !== '' && activeMeter === "" && assetTypeRespStat === "resp" &&
                  singleFacilityData?.assetTypeData[0]?.otherData?.filter((obj: any) => obj.value !== null)
                    .map((obj: any) =>
                      <div key={obj}
                        className="consumptionsCard">
                        <p className="cardHeader">{cardsImageData[obj.productMeterType]?.title}</p>
                        <div className="energyConsumptionReadingCont">
                          <img src={cardsImageData[obj.productMeterType]?.image} alt="icon" />
                          {/* apparentText */}
                          <p className={obj.productMeterType === 'Apparent Power' ? 'subMetersText' : 'subMetersText'}>
                            {
                              cardsImageData[obj.productMeterType]?.title.includes('Consumption') ?
                              findNullValueCheck(obj.value,obj.defaultUom, showLatestDecimalValue): findNullValueCheck(obj.value,obj.defaultUom, 'common')
                            }
                          </p>
                          {obj.productMeterType === 'Apparent Power' && obj?.contractDemand !== undefined && <p className="subMetersText">
                            Contract Demand{" "}
                            <span>
                              { findNullValueCheck(obj?.value, obj?.abbreviation, 'common' ) }
                            </span>
                          </p>}
                        </div>
                      </div>)
                }
                {/* Dynamic cards for facility DATA */}
                {
                  activeFacilityId !== '' && activeMeter === "" && assetTypeRespStat === "resp" &&
                  singleFacilityData?.facilityData[0]?.otherData?.filter((obj: any) => obj.value !== null)
                    .map((obj: any) =>
                      <div key={obj}
                        className="consumptionsCard">
                        <p className="cardHeader">{cardsImageData[obj.productMeterType]?.title}</p>
                        <div className="energyConsumptionReadingCont">
                          <img src={cardsImageData[obj.productMeterType]?.image} alt="icon" />
                          {/* apparentText */}
                          <p className={obj.productMeterType === 'Apparent Power' ? 'subMetersText' : 'subMetersText'}>
                            {
                              cardsImageData[obj.productMeterType]?.title.includes('Consumption') ?
                              findNullValueCheck(obj.value,obj.defaultUom, showLatestDecimalValue): findNullValueCheck(obj.value,obj.defaultUom, 'common')
                            }
                          </p>
                          {obj.productMeterType === 'Apparent Power' && obj?.contractDemand !== undefined && <p className="subMetersText">
                            Contract Demand{" "}
                            <span>
                              { findNullValueCheck(obj?.value, obj?.abbreviation, 'common' ) }
                            </span>
                          </p>}
                        </div>
                      </div>)
                }


                {
                  (activeAssetTypeId !== '' || activeFacilityId !== '') && activeMeter !== "" && assetTypeRespStat === "resp" &&
                  singleFacilityData?.assetData[0]?.otherData?.filter((obj: any) => obj.value !== null)
                    .map((obj: any) =>
                      <div key={obj}
                        className="consumptionsCard">
                        <p className="cardHeader">{cardsImageData[obj.productMeterType]?.title}</p>
                        <div className="energyConsumptionReadingCont">
                          <img src={cardsImageData[obj.productMeterType]?.image} alt="icon" />
                          <p className={obj.productMeterType === 'Apparent Power' ? 'subMetersText' : 'subMetersText'}>
                            {
                              cardsImageData[obj.productMeterType]?.title.includes('Consumption') ?
                              findNullValueCheck(obj.value,obj.defaultUom, showLatestDecimalValue): findNullValueCheck(obj.value,obj.defaultUom, 'common')
                            }
                          </p>
                          {obj.productMeterType === 'Apparent Power' && obj?.contractDemand !== undefined && <p className="subMetersText">
                            Contract Demand{" "}
                            <span>
                                { findNullValueCheck(obj?.value, obj?.abbreviation, 'common' ) }
                            </span>
                          </p>}
                        </div>
                      </div>)
                }

              </div>}

            </div>
          </div>

          { assetTypeRespStat === "resp" && <div
            className={fullSizedComponents.includes('CONSUMPTION_V1') ? 'energyConsumptionGraphsDataContainer' : 'energyConsumptionGraphsDataContainer-HalfWidth'}
          >
            {shiftFlag &&  singleFacilityData?.shiftData && Object.keys(singleFacilityData?.shiftData).length !== 0 && singleFacilityData?.shiftData?.shiftConsumption?.shiftConsumption?.length !== 0 && <div className="shiftWiseEnergyConsumptionCardsCont">
              <div className="shiftWiseConsumptionCard">
                <img src={ShiftWiseIcon} alt="icon" className="shiftWiseIcon" />
                <div className="shiftWiseHeadersContainer">
                  <p className="shiftWiseCradHeader">Shift wise Consumption</p>
                  <p className="shiftWiseCardValues">{findNullValueCheck(overAllShiftWiseConsumption.current, 'L', showLatestDecimalValue)}</p>
                </div>
              </div>
              {singleFacilityData?.shiftData?.shiftConsumption?.shiftConsumption?.length !== 0 &&
                // .filter((obj: any) => obj.consumption.value !== null)
                singleFacilityData?.shiftData?.shiftConsumption?.shiftConsumption?.map((obj: any, index: number) =>
                  <div onClick={() => setActiveShiftId(obj.description)} key={index} className={shiftWiseWidthObj[obj?.description]?.className} style={{ width: shiftWiseWidthObj[obj?.description]?.width, cursor: 'pointer', borderLeft: activeShiftId === obj.description ? '5px solid brown' : '', opacity: activeShiftId === obj.description ? 1 : opacityVal }}>
                    <div>
                      <p className="shiftWiseTextOne">{obj?.description} <span style={{ backgroundColor: activeShiftId === obj.description ? '#FFEF9D' : "" }}>
                        {(obj?.value == null || overAllShiftWiseConsumption.current == 0) ? ' -' :
                        findNullValueCheck((obj?.value / overAllShiftWiseConsumption.current) * 100,'', showLatestDecimalValue )+'%'}</span></p>
                      <p className="shiftWiseTextTwo">{obj?.fromTime} - {obj.thruTime}</p>
                      <p className="shiftWiseTextThree">{ findNullValueCheck(obj?.value,obj?.defaultUom, showLatestDecimalValue ) }</p>
                    </div>
                    {activeShiftId === obj.description && <img src={leftAlignedAssetIcon} alt='icon' />}
                  </div>
                )}
            </div>}

            <div className="energyConsumptionHourlyGraphContainer">
              <div className="energyConsumptionGraphFilters">
                {dropDownDisplayType !== 'Custom Range' && <div className="filterContainer">
                  <div
                    className="filterBtnCont"
                    onClick={() => showDropDownList(!showDropDown)}
                  >
                    <p className="filterHeader">Viewing {dropDownDisplayType}</p>
                    <img src={FilterIcon} alt="icon" />
                  </div>
                  {showDropDown && (
                    <ul className="selectorContainer">
                      {dropDownList.map((list) => (
                        <li
                          style={{
                            cursor: "pointer",
                            color:
                              dropDownDisplayType === list ? "white" : "black",
                            backgroundColor:
                              dropDownDisplayType === list ? "#0075FF" : "",
                          }}
                          key={list}
                          onClick={() => {
                            showDropDownList(false);
                            setDropDownDisplayType(list);
                          }}
                        >
                          {list}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>}


                <div className="filterTypesCont">
                  <div className="filterType">
                    <img src={activeShiftId !== '' ? ShiftGraphIcon : EnergyConsumptionIcon} alt="icon" />
                    <p className="filterTypeHeader">{activeShiftId == '' ? 'Energy Consumption' : `Energy Consumption in ${toPascalCase(activeShiftId).replace("_", " ")} `}</p>
                  </div>
                  {/* {activeMeter === "" && activeShiftId === '' && activeAssetTypeId === "" && <div className="filterType">
                    <img src={EstimatedCostIcon} alt="icon" />
                    <p className="filterTypeHeader">Estimated Cost</p>
                  </div>} */}
                </div>


              </div>
              <div className="energyConsumptionGraphCont">
                {
                  dropDownRespStat === "load" ? (
                    <div className="loader-cont">
                      <LoaderStyle />
                    </div>
                  ) : dropDownRespStat === "empty" ? (
                    <DisplayError type={"err-empty"} />
                  ) : dropDownRespStat === "error" ? (
                    <DisplayError type={"err-500/404"} />
                  ) : (<>
                    {activeAssetTypeId === "" && activeShiftId === '' ? (
                      <ReactEcharts
                        style={{ height: "300px", width: '100%' }}
                        option={chartOptions}
                      />
                    ) : (
                      <div>
                        <ReactEcharts
                          style={{ height: "300px", width: '100%' }}
                          option={chartOptions}
                        />
                      </div>
                    )}</>)
                }
              </div>
            </div>
          </div>}

          <div className="viewAssetNavCont">
            <Link
              to={"/asset-list"}
              className="viewAssetText"
            >{`View Assets>`}</Link>
          </div>
        </>
      )}
    </>
  );
}

export default FuelCardComponent;
