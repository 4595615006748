import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import IconClose from '../../../../../assets/icons/IconClose.svg';
import AddIcon from '../../../../../assets/icons/AddIcon.svg';
import DeleteIcon from '../ViewUserIcons/DeleteIcon.svg';
import Select from "react-select";
import UseStatics from "../../../utils/utils";
import { AssignUserFacilityGroups } from "../service/ViewUserPostData";
import '../AssignUserToFacility/AssignUserToFacility.scss';
export const AssignUserToFacilityGroups = (props) => {
  const { data, setAddToFacilityGroup } = props;
  const { facilityGroupsData, facilityGroupsRoleData, } = UseStatics();
  const [addGroups, setAddGroups] = useState([{ facilityGroup: '', roleFacGrp: '', selectedOptionsFacilityGrps: null, selectedOptionsFacGrpRole: null }]);
  const [openDropdownsFacGrp, setOpenDropdownsFacGrp] = useState(addGroups.map(() => ({ facilityGroup: false, roleFacGrp: false })));
  const [selectedFacilityGroupIds, setSelectedFacilityGroupIds] = useState<any[]>({});
  const [selectedFacilityGroupRoleIds, setSelectedFacilityGroupRoleIds] = useState<any[]>({});
  const handleFacilityGrp = (index, selectedOption) => {
    const updatedAddGroups = [...addGroups];
    updatedAddGroups[index].selectedOptionsFacilityGrps = selectedOption;
    updatedAddGroups[index].facilityGroup = selectedOption?.label || '';
    setAddGroups(updatedAddGroups);
  };

  const handleFacGrpRole = (index, selectedOption) => {
    const updatedAddGroups = [...addGroups];
    updatedAddGroups[index].selectedOptionsFacGrpRole = selectedOption;
    updatedAddGroups[index].roleFacGrp = selectedOption?.label || '';
    setAddGroups(updatedAddGroups);
  };
  const handleAddFacilitiesGroups = () => {
    setAddGroups([...addGroups, { facilityGroup: '', roleFacGrp: '' }]);
    setOpenDropdownsFacGrp([...openDropdownsFacGrp, { facilityGroup: false, roleFacGrp: false }]);
  };
  const handleRemoveFacilitiesGroups = (index: number) => {
    const updatedAddGroups = addGroups.filter((_, i) => i !== index);
    setAddGroups(updatedAddGroups);

    const updatedOpenDropdownsFacGrp = openDropdownsFacGrp.filter((_, i) => i !== index);
    setOpenDropdownsFacGrp(updatedOpenDropdownsFacGrp);

    const updatedSelectedFacilityGroupRoleIds = Object.fromEntries(
      Object.entries(selectedFacilityGroupRoleIds)
        .filter(([key]) => parseInt(key) !== index)
        .map(([key, value]) => [key > index ? key - 1 : key, value])
    );
    setSelectedFacilityGroupRoleIds(updatedSelectedFacilityGroupRoleIds);

    const updatedSelectedFacilityGroupIds = Object.fromEntries(
      Object.entries(selectedFacilityGroupIds)
        .filter(([key]) => parseInt(key) !== index)
        .map(([key, value]) => [key > index ? key - 1 : key, value])
    );
    setSelectedFacilityGroupIds(updatedSelectedFacilityGroupIds);
  };
  const handleAssignUserFacilityGroups = async (e) => {
    setAddToFacilityGroup(false);
    const payLoadAssignUserFacGrp = {
      toAdd: addGroups.map((el) => ({
        facilityGroupId: el.selectedOptionsFacilityGrps.value,
        partyId: data?.userLoginDetails?.[0]?.partyId,
        roleTypeId: el.selectedOptionsFacGrpRole.value,
      })),
      toRemove: [] 
    };  
    const result = await AssignUserFacilityGroups(payLoadAssignUserFacGrp);
    console.log("Assign User Facility Group Result:", result);
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '40px',
      width: '300px',
      border: state.isFocused ? '2px solid #007bff' : '2px solid #ced4da',
      boxShadow: state.isFocused ? '0 0 5px rgba(0, 123, 255, 0.5)' : 'none',
      '&:hover': {
        border: '2px solid #007bff'
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '8px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '8px',
    }),
  };
  useEffect(() => {
    if (data && data.facilityGroupDetails?.length > 0) {
      const defaultValues = data.facilityGroupDetails.map((facilityDetail) => ({
        facilityGroup: facilityDetail.facilityName,
        roleFacGrp: facilityDetail.roleTypeDescription,
        selectedOptionsFacilityGrps: {
          value: facilityDetail.facilityGroupId,
          label: facilityDetail.facilityGroupName,
        },
        selectedOptionsFacGrpRole: {
          value: facilityDetail.roleTypeId,
          label: facilityDetail.roleTypeDescription,
        },
      }));

      setAddGroups(defaultValues);
    }
  }, [data]);
  return (
    <Modal
      show={setAddToFacilityGroup}
      onHide={() => setAddToFacilityGroup(false)}
      centered
      size="lg"
      backdrop="static"
      className="AssignUserFacilityPopupModals"
    >
      <div className="AssignUserFacilityPopUp">
        <div className="AssignUserFacilityHeader">
          <div className="AssignUserFacilityHeaderContent">Map user to facility groups</div>
          <div className="AssignUserFacilityHeaderClose" onClick={() => setAddToFacilityGroup(false)}> <img className="iconCloseImg" src={IconClose} alt="icon"/></div>
        </div>
        <div className="AssignUserFacilityBody">
          <div className="AssignUserFacilityBodyHeaderss">
            Adding <b>{data?.userBasicDetails?.[0]?.firstName} ({data?.emailDetails?.[0]?.commonDetails?.[0]?.infoString})</b> to following facility groups.
          </div>
          <div className="AssignUserFacilityBodyLabels">
            <p>Facility Group</p>
            <p>Role</p>
          </div>
          <div className="AssignUserFacilityDiv">
          {addGroups.map((el, index) => (
          <div className="AssignUserFacilityContainer" key={index}>
            <Select
              value={el.selectedOptionsFacilityGrps}
              styles={customStyles}
              options={facilityGroupsData.map((facility) => ({
                value: facility.id,
                label: facility.name,
              }))}
              onChange={(selectedOption) => handleFacilityGrp(index, selectedOption)}
              isClearable
            />
            <Select
              value={el.selectedFacilityGroupRoleIds}
              styles={customStyles}
              options={facilityGroupsRoleData.map((role) => ({
                value: role.id,
                label: role.name,
              }))}
              isClearable
              onChange={(selectedOption) => handleFacGrpRole(index, selectedOption)}
            />
            <img
              src={DeleteIcon}
              alt="icon"
              className="deleteIcon"
              onClick={() => handleRemoveFacilitiesGroups(index)}
            />
          </div>
        ))}
        </div>
        </div>
        <div className="AssignUserFacilityDetailsBtnCont">
          <button
            className="AssignUserFacilityDetailsBtn"
            onClick={handleAddFacilitiesGroups}
          >
            <img src={AddIcon} alt="icon" />
            Add
          </button>
        </div>
        <Modal.Footer style={{ border: 'none' }}>
          <div className="AssignUserFacilityFooter">
            <button
              onClick={() => setAddToFacilityGroup(false)}
              className="AssignUserFacilityCancelBtn"
            >
              Cancel
            </button>
            <button
              onClick={handleAssignUserFacilityGroups}
              className="AssignUserFacilityMappingBtn"
            >
              {" "}
              Confirm Mapping
            </button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}