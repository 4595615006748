import moment from "moment";
import { API_BASE_URL } from "../../../Constants/constants";

export const getForecastList = async (
    date: any,
    selectedFilterOption: string,
    trendRange: string,
    dateChip: string
  ) => {
    try {
      let startDate = moment(date[0], "YYYY-MM-DD HH:mm:ss").valueOf();
      let endDate = moment(date[1], "YYYY-MM-DD HH:mm:ss").valueOf();

      let frequency = "";
      let range = "";

      if (dateChip === "Custom") {
        frequency = "day";
        range = "";
        if (moment(endDate).isBefore(moment(startDate))) {
          return;
        }
      } else {
        const trendRangeData = trendRange.split(" ");
        range = trendRangeData[1];
        frequency =
          trendRangeData[2] === "days"
            ? "day"
            : trendRangeData[2] === "months"
            ? "month"
            : trendRangeData[2] === "weeks"
            ? "week"
            : "";
      }

      const url = `${API_BASE_URL}/assets/forecastConsumption?fixedAssetTypeId=&fixedAssetId=&filterByType=facility&filterById=_NA_&endDate=${endDate}&startDate=${startDate}&pageIndex=1&pageSize=100&consumptionType=ENERGY&frequency=${frequency}&fetchShiftData=false${
        dateChip !== "Custom" ? `&range=${range}` : ""
      }&productMeterTypeId=${selectedFilterOption}`;

      const options = {
        method: "GET",
      };

      const resp = await fetch(url, options);

      if (!resp.ok) {
        return resp.status;
      }
  
      return await resp.json();
    } catch (error) {
      return error;
    }
  };