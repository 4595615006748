import React, { useState } from "react";
import OptionsIcon from "../../../../assets/icons/OptionsIcon.svg";
import DisabledIcon from "../../../../assets/icons/DisabledIcon.svg";
import "./UserCard.scss";
import { API_BASE_URL_DOWNLOAD } from "../../../../Constants/constants";
import IfImgNotUpld from '../userCard/ViewUserIcons/IfImgNotUpld.svg';
import NewEditUser from "./EditUser/EditUser";
import { ViewUser } from "./ViewUser/ViewUser";
import { AssignUserToFacility } from "./AssignUserToFacility/AssignUserToFacility";
import { AssignUserToFacilityGroups } from "./AssignUserToFacilityGroups/AssignUserToFacilityGroups";
import { EditUser } from "../../editUser/editUser";

function UserCard(props) {
  const { data, addToSelectedList, inputEnabled, handleCheckboxChange } = props;
  const [addToFacilityGroup, setAddToFacilityGroup] = useState(false);
  const [addToFacilityShow, setAddToFacilityShow] = useState(false);
  const [newEditUserPopUp, setNewEditUserPopUp] = useState(false);
  const [cardViewUser, setCardViewUser] = useState(true);
  const [showDisableTooltip, setShowDisableTooltip] = useState(false);
  const [viewUser, setViewUserPopup] = useState(false);

  const setViewUserTrue = () => {
    if (cardViewUser) {
      setViewUserPopup(true);
    }
  };

  return (
    <>
      <div className="userCardCont" onClick={setViewUserTrue}>
        <div className="userCardInputContainer">
          <input
            type="checkbox"
            className="userCardInput"
            checked={inputEnabled}
            onChange={() => handleCheckboxChange(data?.userLoginDetails?.[0]?.partyId)}
            onClick={() => addToSelectedList(data?.userLoginDetails?.[0]?.partyId, data?.userLoginDetails?.[0]?.enabled)}
            onMouseOver={() => setCardViewUser(false)}
            onMouseLeave={() => setCardViewUser(true)}
          />
          <div className="userStatusCont">
            {data?.userLoginDetails?.[0]?.enabled === "Y" ? (
              <p className="userEnableTxt">enabled</p>
            ) : (
              <div className="userDisabledCont">
                <img
                  className="userDisabledImg"
                  src={DisabledIcon}
                  alt="icon"
                  onMouseEnter={() => setShowDisableTooltip(true)}
                  onMouseLeave={() => setShowDisableTooltip(false)}
                />
                {showDisableTooltip && (
                  <div className="tooltip-content">
                    {data?.userLoginDetails?.[0]?.successiveFailedLogins} Successive failed login attempts
                  </div>
                )}
                <p className="userDisabledTxt">disabled</p>
              </div>
            )}
            <div className="profileHoverImgCont">
              <img src={OptionsIcon} alt="icon" className="profileHoverImg" />
              <ul
                className="userHoverCont"
                onMouseOver={() => setCardViewUser(false)}
                onMouseLeave={() => setCardViewUser(true)}
              >
                <li onClick={() => setAddToFacilityShow(true)}>Add To Facility</li>
                <li onClick={() => setAddToFacilityGroup(true)}>Add To Groups</li>
                <li onClick={() => setViewUserPopup(true)}>View User</li>
                <li onClick={() => setNewEditUserPopUp(true)}>Edit User</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="userCardProfileContainer">
          <div className="userProfileImgCont">
            <img
              className="userLogoImage"
              src={
                data.mediaList.length === 0
                  ? IfImgNotUpld
                  : `${API_BASE_URL_DOWNLOAD.slice(0,-1)}${data?.mediaList?.[0]?.url}`
              }
              alt="logo"
            />
          </div>
          <div className="userProfileDetails">
            <div className="userName">
              {data?.userBasicDetails?.[0]?.firstName} {data?.userBasicDetails?.[0]?.lastName}
            </div>
            <div className="userNumber">
              {data?.phoneDetails?.[0]?.commonDetails?.countryCode || '-'}-
              {data?.phoneDetails?.[0]?.commonDetails?.contactNumber || '-'}
            </div>
            <div className="userEmail">
              {data?.emailDetails?.[0]?.commonDetails?.[0]?.infoString || '-'}
            </div>
          </div>
        </div>
        <div className="userCardDetailsCont">
          <div className="userEmail">
            {data?.emailDetails?.[0]?.commonDetails?.[0]?.infoString || '-'}
          </div>
          {data?.userLoginDetails?.[0]?.userName && <div className="userNameDiv">{data?.userLoginDetails?.[0]?.userName}</div>}
        </div>
      </div>
      {/* Assign User to Facility Popup */}
      {addToFacilityShow && (
        <AssignUserToFacility data={data} setAddToFacilityShow={setAddToFacilityShow} />
      )}
      {/* Add to Groups Popup */}
      {addToFacilityGroup && (
        <AssignUserToFacilityGroups data={data} setAddToFacilityGroup={setAddToFacilityGroup} />
      )}
      {/* Edit User Popup */}
      {newEditUserPopUp && (
        <EditUser data={data} setNewEditUserPopUp={setNewEditUserPopUp} />
      )}
      {/* View User Popup */}
      {viewUser && (
        <ViewUser
          data={data}
          setNewEditUserPopUp={setNewEditUserPopUp}
          setViewUserPopup={setViewUserPopup}
        />
      )}
    </>
  );
}

export default UserCard;
