import {useQuery, useQueryClient} from "react-query";
import axios from 'axios';
import { API_BASE_URL } from "../../../Constants/constants";
import LocalStorageService from '../../../Utils/LocalStorageService';
import React, {  useState} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const PostData = async (
  payLoadObj: {
    firstName: string;
    lastName: string;
    addressPurpose: string;
    toName: string;
    attnName: string;
    address1: string;
    address2: string;
    city: string;
    stateProvinceGeoId: string;
    postalCode: string;
    countryGeoId: string;
    emailAddress: string;
    emailPurpose: string;
    phonePurpose: string;
    phoneNumberPrefix: string;
    USERNAME: string;
    PASSWORD: string;
    CONFIRM_PASSWORD: string;
    roleTypeIdList: string;
    assignUserFacility: string;
    assignUserFacilityGroup: string;
    updateUser: boolean;
    partyId: string | null;
  },
  images: File | null,
  queryClient: any,
  setNewEditUserPopUp: boolean,
) => {
  const token = LocalStorageService.getToken("@tokenID");
  const url1 = `${API_BASE_URL}ticketing/attachment`;
  const url = `${API_BASE_URL}person/createPerson`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payLoadObj),
    });
    if (!response.ok) {
      setNewEditUserPopUp(true);
      payLoadObj.updateUser===true? toast.error("Error"+`${response.status}`+":Failed to Edit User"):toast.error("Error"+`${response.status}`+":Failed to Create User");
      throw new Error(`Server Error: ${response.status} - ${response.statusText}`);
    }

    const responseData = await response.json();

    if (responseData.partyId && payLoadObj.updateUser === false && images) {
      const formData = new FormData();
      const tableDetails = {
        isGallery: false,
        enumCode: "PARTY",
        columnNames: [
          { uploadKey: "partyId", uploadValue: responseData.partyId },
          { uploadKey: "partyContentTypeId", uploadValue: "USERDEF" },
        ],
      };

      formData.append("tableDetails", JSON.stringify(tableDetails));
      formData.append("file", images);

      try {
        const attachmentResponse = await axios.post(url1, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("Attachment created successfully:", attachmentResponse.data);
      } catch (attachmentError) {
        console.error("Error uploading attachments:", attachmentError);
        return { error: attachmentError };
      }
    } else if (payLoadObj.updateUser === true) {
      console.log("Using pre-existing images from the API for update.");
    }
    queryClient.invalidateQueries('PostData');
    return responseData;
  } catch (error) {
    console.error("Error:", error);
    toast.error("An error occurred while processing the request.");
    return { error: error.message };
  }
};
// import { useQuery, useQueryClient } from "react-query";
// import axios from "axios";
// import { API_BASE_URL } from "../../../Constants/constants";
// import LocalStorageService from "../../../Utils/LocalStorageService";
// import React, { useRef, useState } from "react";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// export const PostData = async (
//   payLoadObj: {
//     firstName: string;
//     lastName: string;
//     addressPurpose: string;
//     toName: string;
//     attnName: string;
//     address1: string;
//     address2: string;
//     city: string;
//     stateProvinceGeoId: string;
//     postalCode: string;
//     countryGeoId: string;
//     emailAddress: string;
//     emailPurpose: string;
//     phonePurpose: string;
//     phoneNumberPrefix: string;
//     USERNAME: string;
//     PASSWORD: string;
//     CONFIRM_PASSWORD: string;
//     roleTypeIdList: string;
//     assignUserFacility: string;
//     assignUserFacilityGroup: string;
//     updateUser: boolean;
//     partyId: string | null;
//   },
//   images: File | null,
//   queryClient: any,
//   setNewEditUserPopUp: (value: boolean) => void
// ) => {
//   const token = LocalStorageService.getToken("@tokenID");
//   const createPersonUrl = `${API_BASE_URL}person/createPerson`;
//   const attachmentUrl = `${API_BASE_URL}ticketing/attachment`;

//   try {
//     const personResponse = await fetch(createPersonUrl, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payLoadObj),
//     });

//     if (!personResponse.ok) {
//       setNewEditUserPopUp(true);
//       toast.error("Failed to Create User");
//       throw new Error(`Server Error: ${personResponse.status} - ${personResponse.statusText}`);
//     }

//     const responseData = await personResponse.json();

//     if (responseData.partyId && !payLoadObj.updateUser && images) {
//       const formData = new FormData();
//       const tableDetails = {
//         isGallery: false,
//         enumCode: "PARTY",
//         columnNames: [
//           { uploadKey: "partyId", uploadValue: responseData.partyId },
//           { uploadKey: "partyContentTypeId", uploadValue: "USERDEF" },
//         ],
//       };

//       formData.append("tableDetails", JSON.stringify(tableDetails));
//       formData.append("file", images);

//       try {
//         const attachmentResponse = await axios.post(attachmentUrl, formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         console.log("Attachment created successfully:", attachmentResponse.data);
//       } catch (attachmentError) {
//         console.error("Error uploading attachments:", attachmentError);
//         throw new Error("Failed to upload attachments.");
//       }
//     } else if (payLoadObj.updateUser) {
//       console.log("Using pre-existing images from the API for update.");
//     }

//     queryClient.invalidateQueries("PostData");
//     return responseData;
//   } catch (error: any) {
//     console.error("Error in PostData function:", error.message);
//     toast.error("An error occurred while processing the request.");
//     return { error: error.message };
//   }
// };
// using usequery api call***************

// import React, { useEffect, useState } from "react";
// import { useQuery, useQueryClient } from "react-query";
// import axios from 'axios';
// import { API_BASE_URL } from "../../../Constants/constants";
// import LocalStorageService from '../../../Utils/LocalStorageService';

// type PostDataPayload = {
//   firstName: string;
//   lastName: string;
//   addressPurpose: string;
//   toName: string;
//   attnName: string;
//   address1: string;
//   address2: string;
//   city: string;
//   stateProvinceGeoId: string;
//   postalCode: string;
//   countryGeoId: string;
//   emailAddress: string;
//   emailPurpose: string;
//   phonePurpose: string;
//   phoneNumberPrefix: string;
//   USERNAME: string;
//   PASSWORD: string;
//   CONFIRM_PASSWORD: string;
//   roleTypeIdList: string;
//   assignUserFacility: string;
//   assignUserFacilityGroup: string;
//   updateUser : boolean;
//   partyId: string | null;
// };

// const useCreateEditUserApi = (assetId: string) => {
//   const {
//     data: createEditPersonData,
//     error: createEditPersonError,
//     isLoading: createEditPersonLoading,
//     isFetching: createEditPersonFetching,
//     refetch: refetchCreateEditPersonData,
//     dataUpdatedAt: createEditPersonDataUpdatedAt
//   } = useQuery<undefined>(
//     "createEditUserApi",
//     () => PostData(assetId),
//     {
//       enabled: false,
//       keepPreviousData: false,
//     }
//   );

 
//   return {
//     createEditPersonData,
//     createEditPersonError,
//     createEditPersonLoading,
//     createEditPersonFetching,
//     refetchCreateEditPersonData,
//     createEditPersonDataUpdatedAt,
//     PostData,
//   };
// };

// export default useCreateEditUserApi;

// export const PostData = async (
//   payLoadObj: payLoadObj,
//   images: File | null,
//   setNewEditUserPopUp: (value: boolean) => void,
//   invalidateQueries: () => void
// ) => {
//   const token = LocalStorageService.getToken("@tokenID");
//   const createPersonUrl = `${API_BASE_URL}person/createPerson`;
//   const attachmentUrl = `${API_BASE_URL}ticketing/attachment`;

//   try {
//     const response = await fetch(createPersonUrl, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payLoadObj),
//     });

//     if (!response.ok) {
//       setNewEditUserPopUp(true);
//       throw new Error(`Server Error: ${response.status} - ${response.statusText}`);
//     }

//     const responseData = await response.json();

//     if (responseData.partyId && !payLoadObj.updateUser && images) {
//       const formData = new FormData();
//       const tableDetails = {
//         isGallery: false,
//         enumCode: "PARTY",
//         columnNames: [
//           { uploadKey: "partyId", uploadValue: responseData.partyId },
//           { uploadKey: "partyContentTypeId", uploadValue: "USERDEF" },
//         ],
//       };

//       formData.append("tableDetails", JSON.stringify(tableDetails));
//       formData.append("file", images);

//       try {
//         const attachmentResponse = await axios.post(attachmentUrl, formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         console.log("Attachment created successfully:", attachmentResponse.data);
//       } catch (attachmentError) {
//         console.error("Error uploading attachments:", attachmentError);
//         return { error: attachmentError };
//       }
//     } else if (payLoadObj.updateUser) {
//       console.log("Using pre-existing images from the API for update.");
//     }

//     invalidateQueries();
//     return responseData;
//   } catch (error) {
//     console.error("Error:", error);
//     return { error: error instanceof Error ? error.message : "An error occurred" };
//   }
// };





// import React from "react";
// import { useQuery, useQueryClient } from "react-query";
// import axios from 'axios';
// import { API_BASE_URL } from "../../../Constants/constants";
// import LocalStorageService from '../../../Utils/LocalStorageService';

// type PostDataPayload = {
//   firstName: string;
//   lastName: string;
//   addressPurpose: string;
//   toName: string;
//   attnName: string;
//   address1: string;
//   address2: string;
//   city: string;
//   stateProvinceGeoId: string;
//   postalCode: string;
//   countryGeoId: string;
//   emailAddress: string;
//   emailPurpose: string;
//   phonePurpose: string;
//   phoneNumberPrefix: string;
//   USERNAME: string;
//   PASSWORD: string;
//   CONFIRM_PASSWORD: string;
//   roleTypeIdList: string;
//   assignUserFacility: string;
//   assignUserFacilityGroup: string;
//   updateUser:  boolean;
//   partyId: string | null;
// };

// const useCreateEditUserApi = () => {
//   const queryClient = useQueryClient();

//   const {
//     data: createEditPersonData,
//     error: createEditPersonError,
//     isLoading: createEditPersonLoading,
//     isFetching: createEditPersonFetching,
//     refetch: refetchCreateEditPersonData,
//     dataUpdatedAt: createEditPersonDataUpdatedAt
//   } = useQuery<undefined>(
//     ["createEditUser Api"], 
//     () => fetchCreateEditPersonData(),
//     {
//       enabled: false,
//       keepPreviousData: false,
//     }
//   );

//   return {
//     createEditPersonData,
//     createEditPersonError,
//     createEditPersonLoading,
//     createEditPersonFetching,
//     refetchCreateEditPersonData,
//     createEditPersonDataUpdatedAt,
//     createUserOrUpdate,
//   };
// };

// const fetchCreateEditPersonData = async () => {
//   return undefined;
// };

// const createUserOrUpdate = async (
//   payLoadObj: PostDataPayload,
//   images: File | null,
//   setNewEditUserPopUp: (value: boolean) => void,
//   queryClient: any
// ) => {
//   const token = LocalStorageService.getToken("@tokenID");
//   const createPersonUrl = `${API_BASE_URL}person/createPerson`;
//   const attachmentUrl = `${API_BASE_URL}ticketing/attachment`;

//   try {
//     const response = await fetch(createPersonUrl, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payLoadObj),
//     });

//     if (!response.ok) {
//       setNewEditUserPopUp(true);
//       throw new Error(`Server Error: ${response.status} - ${response.statusText}`);
//     }

//     const responseData = await response.json();

//     if (responseData.partyId) {
//       if (!payLoadObj.updateUser  && images) {
//         await uploadAttachment(responseData.partyId, images, token);
//       } else if (payLoadObj.updateUser ) {
//         console.log("Using pre-existing images from the API for update.");
//       }
//     }

//     queryClient.invalidateQueries(['createEditUser Api', payLoadObj.partyId]);
//     return responseData;
//   } catch (error) {
//     console.error("Error:", error);
//     return { error: error instanceof Error ? error.message : "An error occurred" };
//   }
// };

// const uploadAttachment = async (partyId: string, images: File, token: string) => {
//   const attachmentUrl = `${API_BASE_URL}ticketing/attachment`;
//   const formData = new FormData();
//   const tableDetails = {
//     isGallery: false,
//     enumCode: "PARTY",
//     columnNames: [
//       { uploadKey: "partyId", uploadValue: partyId },
//       { uploadKey: "partyContentTypeId", uploadValue: "USERDEF" },
//     ],
//   };

//   formData.append("tableDetails", JSON.stringify(tableDetails));
//   formData.append("file", images);

//   try {
//     const attachmentResponse = await axios.post(attachmentUrl, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     console.log("Attachment created successfully:", attachmentResponse.data);
//   } catch (attachmentError) {
//     console.error("Error uploading attachments:", attachmentError);
//     throw new Error("Attachment upload failed");
//   }
// };

// export default useCreateEditUserApi;