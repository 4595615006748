import { useEffect, useState } from 'react';
import LocalStorageService from '../../../Utils/LocalStorageService';

const UseStatics = () => {
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [facilityGroupsData, setFacilityGroupsData] = useState([]);
  const [emailPurposeData, setEmailPurposeData] = useState([]);
  const [phonePurposeData, setPhonePurposeData] = useState([]);
  const [addressPurposeData, setAddressPurposeData] = useState([]);
  const [countryArr, setCountryArr] = useState([]);
  const [statesArr, setStatesArr] = useState([]);
  const [rolesArr, setRolesArr] = useState([]);
  const [facilityRoleData, setFacilityRoleData] = useState([]);
  const [facilityGroupsRoleData, setFacilityGroupsRoleData] = useState([]);
  useEffect(() => {
    let statics = LocalStorageService.getFacilityStaticsData();
    let loginApis = LocalStorageService.getFacilityData();
    let facilityGroups = LocalStorageService.getClusterData();
    console.log("ROLESIDS IN LOCAL STORAGE",LocalStorageService);
    const facilityIds = loginApis.map((item: { facilityId: any; facilityName: any; }) => ({
      id: item.facilityId,
      name: item.facilityName,
    }));
    setFacilitiesData(facilityIds);
    console.log("statics",statics);
    const { data:
      { contactPurposes: { EMAIL_ADDRESS, TELECOM_NUMBER, POSTAL_ADDRESS },
        countries,
        productBasedRoles:{roleList}
      }
    } = statics;

    const facilityGrpsIds = facilityGroups.map((item: { facilityGroupTypeId: any; facilityGroupName: any; }) => ({
      id: item.facilityGroupTypeId,
      name: item.facilityGroupName
    }))
    setFacilityGroupsData(facilityGrpsIds);

    const processedDataEmail = EMAIL_ADDRESS.map((item: any) => ({
      id: item.contactMechPurposeTypeId,
      name: item.description,
      value: item.contactMechTypeId,

    }));
    setEmailPurposeData(processedDataEmail);

    const processedDataPhone = TELECOM_NUMBER.map((item: any) => ({
      id: item.contactMechPurposeTypeId,
      name: item.description,
      value: item.contactMechTypeId,

    }));
    setPhonePurposeData(processedDataPhone);

    const processedDataAddress = POSTAL_ADDRESS.map((item: any) => ({
      id: item.contactMechPurposeTypeId,
      name: item.description,
      value: item.contactMechTypeId,

    }));
    setAddressPurposeData(processedDataAddress);
    const processedDataCountry = countries.map((item: any) => ({
      id: item.geoId,
      name: item.geoName,
      contentUrl: item.contentUrl,
      teleCode: item.teleCode,
      states: item.states,
    }));
    const processedDataRoles=roleList.map((item:any)=>({
      id:item.roleTypeId,
      name:item.description,
    }))
    setRolesArr(processedDataRoles);
    setFacilityRoleData(processedDataRoles);
    setFacilityGroupsRoleData(processedDataRoles);
    setStatesArr(processedDataCountry.states);
    setCountryArr(processedDataCountry);
  }, []);
  return {
    facilitiesData,
    facilityGroupsData,
    emailPurposeData,
    phonePurposeData,
    addressPurposeData,
    countryArr,
    statesArr,
    rolesArr,
    facilityRoleData,
    facilityGroupsRoleData,
  };
};
export default UseStatics;