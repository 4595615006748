import React, { useEffect, useState } from "react";
import { useQuery,useQueryClient } from 'react-query';
import AddIcon from "../../../assets/icons/AddIcon.svg";
import deleteIcon from "../../../assets/icons/addFacDeleteIcon.png";
import IconClose from "../../../assets/icons/IconClose.svg";
import Modal from "react-bootstrap/Modal";
import "./CreateUser.scss";
import Unchecked from '../../../assets/icons/Checked.svg';
import Checked from '../../../assets/icons/Unchecked.svg';
import UseStatics from "../utils/utils";
import { PostData } from "../service/PostData";
import Select from "react-select";
import { AddressPopUp } from "./AddressPopUp";
import { BasicContainer } from "./BasicContainer";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import { usersDropDownCustomStyle } from "../editUser/customStylesReactSelect";
export const CreateUser = (props) => {
  const { setAddUserPopup } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRoles, setSelectedRoles] = useState<{ id: number; name: string }[]>([]);
  const [selectedRolesIds, setSelectedRolesIds] = useState<number[]>([]);
  const {
    facilitiesData,
    facilityGroupsData,
    rolesArr,
    facilityRoleData,
    facilityGroupsRoleData,
  } = UseStatics();
  const [selectedRoleIds, setSelectedRoleIds] = useState<any[]>({});
  const [selectedFacilityIds, setSelectedFacilityIds] = useState<{ [index: number]: string }>({});
  const [selectedFacilityGroupIds, setSelectedFacilityGroupIds] = useState<any[]>({});
  const [selectedFacilityGroupRoleIds, setSelectedFacilityGroupRoleIds] = useState<any[]>({});
  const [combinedFacilityAndRole, setCombinedFacilityAndRole] = useState<{ facilityId: number, roleTypeId: number }[]>([]);
  const [combinedFacilityGroupAndRole, setCombinedFacilityGroupAndRole] = useState<{ facilityGroupId: number, roleTypeId: number }[]>([]);
  const [add, setAdd] = useState([
    { facility: "", role: "", selectedOptionsFacility: null, selectedOptionsRole: null },
  ]);
  const [addGroups, setAddGroups] = useState([{ facilityGroup: '', roleFacGrp: '', selectedOptionsFacilityGrps: null, selectedOptionsFacGrpRole: null }]);
  const [openDropdowns, setOpenDropdowns] = useState(add.map(() => ({ facility: false, role: false })));
  const [openDropdownsFacGrp, setOpenDropdownsFacGrp] = useState(addGroups.map(() => ({ facilityGroup: false, roleFacGrp: false })));
  const [activeTab, setActiveTab] = useState("basic");
  const [validationMessages, setValidationMessages] = useState({});
  const [showUserAddAddress, setshowUserAddAddress] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [basicInfo, setBasicInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    userName: "",
    password: "",
    confirmPassword: "",
    clickPassword: false,
    confirmClickPassword: false,
    toName: "",
    attnName: "",
    addressLine1: "" || null,
    addressLine2: "" || null,
    city: "",
    postalCode: "",
    selectedAddressData: [],
    stateProvinceGeoId: "",
    countryGeoId: "",
    selectedEmailPurposeData: [],
    selectedPhonePurposeData: [],
    selectedPhoneNumberPrefix: "",
    selectedState:"",
    selectedCountry:"",
  });
  const queryClient = useQueryClient();
  const [imageCreate,setImageCreate]=useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payLoadObj = {
      firstName: basicInfo.firstName,
      lastName: basicInfo.lastName,
      addressPurpose: basicInfo.selectedAddressData,
      toName: basicInfo.toName,
      attnName: basicInfo.attnName,
      address1: basicInfo.addressLine1,
      address2: basicInfo.addressLine2,
      city: basicInfo.city,
      stateProvinceGeoId: basicInfo.selectedState,
      postalCode: basicInfo.postalCode,
      countryGeoId: basicInfo.selectedCountry,
      emailAddress: basicInfo.email,
      emailPurpose: basicInfo.selectedEmailPurposeData,
      phonePurpose: basicInfo.selectedPhonePurposeData,
      phoneNumberPrefix: basicInfo.selectedPhoneNumberPrefix,
      phoneNumber: basicInfo.phoneNumber,
      USERNAME: basicInfo.userName,
      PASSWORD: basicInfo.password,
      CONFIRM_PASSWORD: basicInfo.confirmPassword,
      roleTypeIdList: selectedRolesIds,
      assignUserFacility: combinedFacilityAndRole,
      assignUserFacilityGroup: combinedFacilityGroupAndRole,
      updateUser: false,
      partyId: null,
    };
    await PostData(payLoadObj, imageCreate, queryClient, setAddUserPopup);
  };
  /*using usequery */
  const {
    data: createEditPersonData,
    error: createEditPersonError,
    isLoading: createEditPersonLoading,
    isFetching: createEditPersonFetching,
    // refetch: refetchCreateEditPersonData,
    // dataUpdatedAt: createEditPersonDataUpdatedAt
  } = useQuery<undefined>(
    ["createEditUserApi"], 
    () => PostData(payLoadObj, imageCreate, queryClient, setAddUserPopup),
    {
      enabled: false,
      keepPreviousData: false,
    }
  );
  const handleAddFacilities = () => {
    setAdd([...add, { facility: '', role: '' }]);
    setOpenDropdowns([...openDropdowns, { facility: false, role: false }]);
  };

  const handleRemoveFacilities = (index: number) => {
    const updatedAdd = add.filter((_, i) => i !== index);
    setAdd(updatedAdd);

    const updatedOpenDropdowns = openDropdowns.filter((_, i) => i !== index);
    setOpenDropdowns(updatedOpenDropdowns);

    const updatedSelectedRoleIds = Object.fromEntries(
      Object.entries(selectedRoleIds)
        .filter(([key]) => parseInt(key) !== index)
        .map(([key, value]) => [key > index ? key - 1 : key, value])
    );
    setSelectedRoleIds(updatedSelectedRoleIds);

    const updatedSelectedFacilityIds = Object.fromEntries(
      Object.entries(selectedFacilityIds)
        .filter(([key]) => parseInt(key) !== index)
        .map(([key, value]) => [key > index ? key - 1 : key, value])
    );
    setSelectedFacilityIds(updatedSelectedFacilityIds);
  };
  const handleAddFacilitiesGroups = () => {
    setAddGroups([...addGroups, { facilityGroup: '', roleFacGrp: '' }]);
    setOpenDropdownsFacGrp([...openDropdownsFacGrp, { facilityGroup: false, roleFacGrp: false }]);
  };

  const handleRemoveFacilitiesGroups = (index: number) => {
    const updatedAddGroups = addGroups.filter((_, i) => i !== index);
    setAddGroups(updatedAddGroups);

    const updatedOpenDropdownsFacGrp = openDropdownsFacGrp.filter((_, i) => i !== index);
    setOpenDropdownsFacGrp(updatedOpenDropdownsFacGrp);

    const updatedSelectedFacilityGroupRoleIds = Object.fromEntries(
      Object.entries(selectedFacilityGroupRoleIds)
        .filter(([key]) => parseInt(key) !== index)
        .map(([key, value]) => [key > index ? key - 1 : key, value])
    );
    setSelectedFacilityGroupRoleIds(updatedSelectedFacilityGroupRoleIds);

    const updatedSelectedFacilityGroupIds = Object.fromEntries(
      Object.entries(selectedFacilityGroupIds)
        .filter(([key]) => parseInt(key) !== index)
        .map(([key, value]) => [key > index ? key - 1 : key, value])
    );
    setSelectedFacilityGroupIds(updatedSelectedFacilityGroupIds);
  };
  const requiredFields = ["firstName", "lastName", "email", "phoneNumber", "userName", "password", "confirmPassword"];
  const validateForm = () => {
    let isFormValid = true;
    const newValidationMessages = { ...validationMessages };
    const shouldPerformValidation = shouldValidate || ["basic", "roles"];
    if (shouldPerformValidation) {
      if (activeTab === "basic") {
        for (let field of requiredFields) {
          if (!basicInfo[field]) {
            newValidationMessages[field] = "This field is mandatory";
            isFormValid = false;
          } else if (validationMessages[field]) {
            newValidationMessages[field] = "";
          }
        }

        if (basicInfo.password && basicInfo.confirmPassword) {
          if (basicInfo.password !== basicInfo.confirmPassword) {
            newValidationMessages.confirmPassword = "Passwords do not match";
            isFormValid = false;
          } else {
            newValidationMessages.confirmPassword = "";
          }
        }
      }
      if (activeTab === "roles") {
        newValidationMessages.selectedRoles = selectedRoles.length === 0 ? "This field is mandatory" : "";
        isFormValid = selectedRoles.length > 0;
      }

      if (combinedFacilityAndRole.length > 0) {
        if (activeTab === "facilities") {
          combinedFacilityAndRole.forEach((el, index) => {
            if ((el.selectedOptionsFacility || el.selectedOptionsFacilityGroup) && !el.selectedOptionsRole) {
              newValidationMessages[`facilityRole_${index}`] = "Role selection is mandatory when a facility or facility group is selected";
              isFormValid = false;
            } else {
              delete newValidationMessages[`facilityRole_${index}`];
            }
          });
          newValidationMessages.combinedFacilityAndRole = combinedFacilityAndRole.length === 0 ? "This field is mandatory" : "";
        }
      }
      if (activeTab === "groups") {
        newValidationMessages.combinedFacilityGroupAndRole = combinedFacilityGroupAndRole.length === 0 ? "This field is mandatory" : "";
      }
    }
    setValidationMessages(newValidationMessages);
    if (isFormValid) {
      setShouldValidate(false);
    }

    return isFormValid;
  };

  const handleNextClick = () => {
    setShouldValidate(true);
    if (validateForm()) {
      const nextTab =
        activeTab === "basic" ? "roles" :
          activeTab === "roles" ? "facilities" :
            activeTab === "facilities" ? "groups" : "";

      if (nextTab) {
        setActiveTab(nextTab);
      }
    }
  };
  const handleTabClick = (tab: React.SetStateAction<string>) => {
    if (tab === activeTab) return;
    setShouldValidate(true);
    if (validateForm()) {
      setActiveTab(tab);
    }
  };

  const [filteredRoles, setFilteredRoles] = useState<any[]>([]);
  const filterRoles = (role) => {
    const { id, name } = role;

    if (selectedRolesIds.includes(id)) {
      setSelectedRoles((prevSelected) =>
        prevSelected.filter((selectedRole) => selectedRole.id !== id)
      );
      setSelectedRolesIds((prevSelected) =>
        prevSelected.filter((roleId) => roleId !== id)
      );
    } else {
      setSelectedRoles((prevSelected) => [...prevSelected, { id, name }]);
      setSelectedRolesIds((prevSelected) => [...prevSelected, id]);
    }
  };
  const handleFacility = (index, selectedOption) => {
    const updatedAdd = [...add];
    updatedAdd[index].selectedOptionsFacility = selectedOption;
    updatedAdd[index].facility = selectedOption?.label || '';
    setAdd(updatedAdd);
  };

  const handleRole = (index, selectedOption) => {
    const updatedAdd = [...add];
    updatedAdd[index].selectedOptionsRole = selectedOption;
    updatedAdd[index].role = selectedOption?.label || '';
    setAdd(updatedAdd);
  };
  const handleFacilityGrp = (index, selectedOption) => {
    const updatedAddGroups = [...addGroups];
    updatedAddGroups[index].selectedOptionsFacilityGrps = selectedOption;
    updatedAddGroups[index].facilityGroup = selectedOption?.label || '';
    setAddGroups(updatedAddGroups);
  };

  const handleFacGrpRole = (index, selectedOption) => {
    const updatedAddGroups = [...addGroups];
    updatedAddGroups[index].selectedOptionsFacGrpRole = selectedOption;
    updatedAddGroups[index].roleFacGrp = selectedOption?.label || '';
    setAddGroups(updatedAddGroups);
  };

  useEffect(() => {
    setFilteredRoles(
      rolesArr.filter((role) => {
        if (role && role.name) {
          return role.name.toLowerCase().includes(searchTerm.toLowerCase());
        }
      })
    );
  }, [rolesArr, searchTerm]);
  useEffect(() => {
    const combined = add
      .map((item) => ({
        facilityId: item.selectedOptionsFacility ? item.selectedOptionsFacility.value : null,
        roleTypeId: item.selectedOptionsRole ? item.selectedOptionsRole.value : null,
      }))
      .filter(
        (item) => item.facilityId !== null && item.roleTypeId !== null
      );
      console.log("combined",combined)

    setCombinedFacilityAndRole(combined);
  }, [add]);
  useEffect(() => {
    const combinedGroups = addGroups
      .map((item) => ({
        facilityGroupId: item.selectedOptionsFacilityGrps ? item.selectedOptionsFacilityGrps.value : null,
        roleTypeId: item.selectedOptionsFacGrpRole ? item.selectedOptionsFacGrpRole.value : null,
      }))
      .filter(
        (item) => item.facilityGroupId !== null && item.roleTypeId !== null
      );
    setCombinedFacilityGroupAndRole(combinedGroups);
  }, [addGroups]);

console.log("createditpersonsdata",createEditPersonData)
useEffect(()=>{
  setBasicInfo(basicInfo)
},[basicInfo])
  return (
    <div>
          <Modal
          show={setAddUserPopup}
          onHide={() => setAddUserPopup(false)}
          centered
          size="lg"
          backdrop="static"
          className="createUserPopupModal"
        >
       <div className="EditUserPopUp">
          <><div className="EditUserHeader">
          <p className="EditUserHeaderContent">Create new user</p>
          <div className="EditUserHeaderClose" onClick={() => setAddUserPopup(false)}> <img className="iconCloseImg" src={IconClose} alt="icon" /></div>
        </div>
        <div className="EditUserHeaderNav">
          {[{ label: 'Basic Information', tabId: 'basic' }, { label: 'Roles', tabId: 'roles' }, { label: 'Facilities', tabId: 'facilities' }, { label: 'Facility Groups', tabId: 'groups' }].map((el) => (
            <p
              key={el.tabId}
              className={activeTab === el.tabId ? "activeditUserNavTxt" : "editUserNavTxt"}
              onClick={() => handleTabClick(el.tabId)}
            >
              {el.label}
            </p>
          ))}
        </div>
        {activeTab === "basic" && (
          <BasicContainer setBasicInfo={setBasicInfo} setValidationMessages={setValidationMessages} setshowUserAddAddress={setshowUserAddAddress} setShouldValidate={setShouldValidate} validationMessages={validationMessages} basicInfo={basicInfo} setImageCreate={setImageCreate} imageCreate={imageCreate}/>
        )}
        {activeTab === "roles" && (
          <div className="rolesContainer">
            <div className="rolesAddSearchCont">
              <input className="searchInput"  type="search"  placeholder="Search"  value={searchTerm}  onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
            <div className="userRolesCheckBox">
              {filteredRoles.map((el) => (
                <div className="addressPurposeCont" key={el.id}>
                  {selectedRolesIds.includes(el.id) ? (
                    <img src={Checked} alt="checked" onClick={() => filterRoles(el)}/>
                  ) : (
                    <img src={Unchecked}   alt="unchecked" onClick={() => filterRoles(el)}/>
                  )}
                  <div className="rolesName">
                    <p className="textRoles">{el.name}</p>
                  </div>
                </div>
              ))}

            </div>
          </div>
        )}
         {activeTab === "facilities" && (
          <div className="facilitiesContainer">
            <div className="facilitiesAddBtnCont">
              <button className="facilitiesAddBtn" onClick={handleAddFacilities}>
                <img src={AddIcon} alt="icon" />
                Add
              </button>
            </div>
            <div className="facilityRoleDiv">
              <div className="facilityAndRoleHeaders">
                <p>Facility</p>
                <p>Role</p>
              </div>

              <div className="facilityContainerDiv">
                {add.map((el, index) => (
                  <div className="AssignUserFacilityContainer" key={index}>
                    <Select
                      value={el.selectedOptionsFacility}
                      styles={usersDropDownCustomStyle}
                      classNamePrefix="usersDropdown"
                      options={facilitiesData.map((ele) => ({
                        value: ele.id,
                        label: ele.name,
                      }))}
                      onChange={(selectedOption) => handleFacility(index, selectedOption)}
                      isClearable
                    />
                    <Select
                      value={el.selectedOptionsRole}
                      styles={usersDropDownCustomStyle} 
                      classNamePrefix="usersDropdown"
                      options={facilityRoleData.map((role) => ({
                        value: role.id,
                        label: role.name,
                      }))}
                      isClearable
                      onChange={(selectedOption) => handleRole(index, selectedOption)}
                    />
                    {validationMessages[`facilityRole_${index}`] && (
                      <div className="warningDiv" style={{ color: 'red', width: '100%' }}>
                        {validationMessages[`facilityRole_${index}`]}
                      </div>
                    )}
                    <img src={deleteIcon} alt="icon" className="deleteIcon" onClick={() => handleRemoveFacilities(index)} />
                  </div>
                ))}
              </div>

            </div>
          </div>
        )}
        {activeTab === "groups" && (
          <div className="facilitiesContainer">
            <div className="facilitiesAddBtnCont">
              <button className="facilitiesAddBtn" onClick={handleAddFacilitiesGroups}>
                <img src={AddIcon} alt="icon" />
                Add
              </button>
            </div>
            <div className="facilityRoleDiv">
              <div className="facilityAndRoleHeaders">
                <p>Facility Group</p>
                <p>Role</p>
              </div>
              <div className="facilityContainerDiv">
                {addGroups.map((el, index) => (
                  <div className="AssignUserFacilityContainer" key={index}>
                    <Select
                      value={el.selectedOptionsFacilityGrps}
                      styles={usersDropDownCustomStyle}
                      classNamePrefix="usersDropdown"
                      options={facilityGroupsData.map((ele) => ({
                        value: ele.id,
                        label: ele.name,
                      }))}
                      onChange={(selectedOption) => handleFacilityGrp(index, selectedOption)}
                      isClearable
                    />
                    <Select
                      value={el.selectedOptionsFacGrpRole}
                      styles={usersDropDownCustomStyle}
                      classNamePrefix="usersDropdown"
                      options={facilityGroupsRoleData.map((el) => ({
                        value: el.id,
                        label: el.name,
                      }))}
                      isClearable
                      onChange={(selectedOption) => handleFacGrpRole(index, selectedOption)}
                    />
                    <img  src={deleteIcon}  alt="icon"  className="deleteIcon" onClick={() => handleRemoveFacilitiesGroups(index)}/>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="footerContainer">
          <div className="buttonsContainer">
            <button className="userCancelBtn" onClick={() => setAddUserPopup(false)}>
              Cancel
            </button>
            {activeTab !== "groups" ? (
              <button
                className={(activeTab === "roles") ? (selectedRolesIds.length === 0 ? "userNextBtnOpc" : "userNextBtn") : "userNextBtn"}
                onClick={handleNextClick}
                style={{ backgroundColor: "blue" }}
              >
                Next
              </button>
            ) : (
              <button className="userCreateBtn" onClick={handleSubmit}> Create User </button>
            )}
          </div>
        </div></>
        
        </div>
        </Modal>
      {showUserAddAddress && <AddressPopUp setshowUserAddAddress={setshowUserAddAddress} setBasicInfo={setBasicInfo} setValidationMessages={setValidationMessages} setshowUserAddAddress={setshowUserAddAddress} setShouldValidate={setShouldValidate} validationMessages={validationMessages} basicInfo={basicInfo}/>}
    </div>
  )
}