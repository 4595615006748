import React, { useEffect, useRef } from "react";
import downloadIcon from "./../../assets/icons/viewReportDownloadIcon.svg";
import DownloadIcon from "../../assets/icons/downlloadIcon.svg";
import CloseIcon from "../../assets/icons/CloseButton.svg";
import TempInputFied from "./TempInputFied/TempInputFied";
import * as R from "ramda";
import moment from "moment";
import DateRangeSelectorInput from "./DateRangeSelectorInput/DateRangeSelectorInput";
import DateTimeRangeSelectorInput from "./DateTimeRangeSelectorInput/DateTimeRangeSelectorInput";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import TimeRangePicker from "./TimeRangePicker/TimeRangePicker";
import ReportDatePicker from "./DatePicker/ReportDatePicker";
import TimePicker from "./TimePicker/TimePicker";
import ReportDateTimePicker from "./ReportDateTimePicker/ReportDateTimePicker";
import {
  DateDataType,
  ReportCardData,
  ReportCardType,
  ReportResponseCard,
  TimeInputType,
} from "./DataTypes";
import DateFormat from "./DateFormatters/DateFormat";
import Select, { components } from "react-select";
import "./ReportCard.scss";
import LocalStorageService from "../../Utils/LocalStorageService";
import { useStateValue } from "../../redux/StateProvider";
import {
  reportDropDownCustomStyle,
  roundToDecimalNumber,
} from "../../Utils/utils";
import AssetFilterUtils from "../../Utils/AssetFilterUtils";
import useState from "react-usestateref";
import { SelectedOptionType } from "./types";
import { ReportClass } from "./utils";
import { API_BASE_URL } from "../../Constants/constants";
import { SearchListType } from "../../features/assetList/types";
import { DATE } from "@amcharts/amcharts4/core";
import { addDays, subDays, subMonths, subWeeks } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TrendDatePicker from "./TrendDatePicker/TrendDatePicker";
// import TrendDatePicker from "./TrendDatePicker/TrendDatePicker";

export const ReportCard = ({
  reportCardData,
  activeState,
  active,
  callBackRepoStatus,
  isAssetAvailable,
  initialAssetListData,
}: ReportCardType) => {
  const [data, setData] = useState<ReportCardData>({
    contentId: "",
    contentName: "",
    attributeProperties: [],
    attributeValue: "",
  });
  const [cardShow, setCardShow] = useState(false);
  const [repoLoader, setRepoLoader] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState<any>("");
  const [minTemp, setMinTemp] = useState<any>("");
  const [maxTemp, setMaxTemp] = useState<any>("");
  const [formatList, setFormatList] = useState<any>([]);
  const [dateRangeValue, setDateRangeValue] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const [payloadDateValue, setPayloadDateValue] = useState<DateDataType>({
    startDate: "",
    endDate: "",
  });
  const [attributeProperties, setAttributeProperties] = useState<any>([]);
  const [downloadBtnStatus, setDownloadBtnStatus] = useState(false);
  const [activeInput, setActiveInput] = useState("");
  const dateFormatter = new DateFormat();

  const assetFilterUtils = new AssetFilterUtils();

  // For Asset Category
  const [categoryFilterData, setCategoryFilterData] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const [
    selectedCategoryType,
    setSelectedCategoryType,
    selectedCategoryTypeRef,
  ] = useState<string[]>([]);

  // For Asset Type
  const [assetTypeList, setAssetTypeList] = useState<
    { id: string; name: string; parentId: string }[]
  >([]);
  const [filterAssetTypeList, setFilterAssetTypeList] = useState<
    { id: string; name: string; parentId: string }[]
  >([]);
  const [selectedAssetType, setSelectedAssetType] = useState<string[]>([]);
  const [selectedAssetTypeObject, setSelectedAssetTypeObject] = useState<
    SelectedOptionType[]
  >([]);

  // For Assets
  const [assetListOptions, setAssetListOptions] = useState<
    {
      value: string;
      label: string;
      key: string;
    }[]
  >([]);
  const [filteredAssets, setFilteredAssets] = useState<
    {
      value: string;
      label: string;
      key: string;
    }[]
  >();
  const [selectedAsset, setSelectedAsset] = useState<string[]>([]);
  const [selectedAssetObject, setSelectedAssetObject] = useState<
    SelectedOptionType[]
  >([]);

  // Utility Field
  const [utilityData, setUtilityData] = useState([]);
  const [selectedUtility, setSelectedUtility] = useState([]);

  const formatSelectedUtility = (selectedUtility: any) => {
    return Array.isArray(selectedUtility)
      ? selectedUtility.join(",")
      : selectedUtility || "";
  };

  useEffect(() => {
    getTabs();
  }, []);

  const getTabs = () => {
    const loginData = localStorage.getItem("aoneLoginData");
    if (loginData) {
      const subscribedTabs = JSON.parse(loginData)
        .loginDetails[0].product.map((obj: any) => ({
          label: obj.productName.split(" ")[1],
          value: obj.productName.split(" ")[1].toUpperCase(),
        }))
        .filter((item: any) => item.value !== "TEMPERATURE");

      setUtilityData(subscribedTabs);
    }
  };

  // For Facility
  const [facilityListData, setFacilityListData] = useState<
    SelectedOptionType[]
  >([]);
  const [selectedFacilityData, setSelectedFacilityData] = useState<string[]>(
    []
  );

  const pageIndex = useRef<number>(1);

  const [{ utilUserAssets }, dispatch] = useStateValue();
  const [count, setCount] = useState(0);

  const handleKeyDown = (event) => {
    const isInputFocused = event.target.tagName === "INPUT";
    if (event.key === " " && !isInputFocused) {
      event.preventDefault();
    }
    if (event.key === 'Backspace' && inputValue === '') {
        event.preventDefault();
    }
    if (event.key === ' ' && !isInputFocused) {
        event.preventDefault();
    }
};

  const getAssetListData = async (searchByValue: SearchListType[]) => {
    if (!isAssetAvailable) {
      return;
    }

    const body = {
      viewIndex: pageIndex.current,
      viewSize: 1000,
      isLowDataMode: true,
      withLiveMeters: false,
      withCardMeters: false,
      isMonitoring: false,
      withAssetAttributes: false,
      searchBy: searchByValue,
    };

    const url = `${API_BASE_URL}assets/assetList`;

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const response = await resp.json();
      const result: {
        value: string;
        label: string;
        key: string;
      }[] = response?.assets?.map((item) => ({
        value: item?.assetId,
        label: item?.assetName,
        key: item?.assetId,
      }));

      setAssetListOptions(result);
      setFilteredAssets(result);
      setSelectedAssetObject([]);
      setCount((prev) => prev + 1);
    } catch (error) {}
  };

  const getData = () => {
    const utils = new ReportClass();
    const facilityList = utils.getFacilityList();
    setFacilityListData(facilityList);

    const assetCategoryList = utils.getAssetCategoryFilterData();
    setCategoryFilterData(assetCategoryList);

    const assetTypeList = utils.getAssetTypeFilterData();
    setAssetTypeList(assetTypeList);
    setFilterAssetTypeList(assetTypeList);
  };

  const searchAsset = (inputValue: string) => {
    const filterAsset = assetListOptions.filter((item) =>
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    if (filterAsset.length !== 0) {
      setFilteredAssets([...filterAsset]);
    } else {
      let searchValue: SearchListType[] = [];

      if (selectedAssetType.length !== 0) {
        searchValue.push({
          isSearch: true,
          searchKey: "fixedAssetTypeId",
          searchValue: selectedAssetType,
        });
      }

      if (selectedFacilityData.length !== 0) {
        searchValue.push({
          isSearch: true,
          searchKey: "locatedAtFacilityId",
          searchValue: selectedFacilityData,
        });
      }

      if (selectedCategoryType.length !== 0) {
        searchValue.push({
          isSearch: true,
          searchKey: "parentTypeId",
          searchValue: selectedCategoryType,
        });
      }

      const inputValueNew = inputValue.trim();

      if (inputValueNew !== "") {
        searchValue.push({
          isSearch: true,
          searchKey: "fixedAssetName",
          searchValue: [inputValueNew],
        });
      }
      getAssetListData(searchValue);
    }
  };

  useEffect(() => {
    const allAssetData = LocalStorageService.getUserSpecificAssetData();
    dispatch({ type: "UTIL_USER_ASSETS", utilUserAssets: allAssetData });
    getData();
  }, []);

  useEffect(() => {
    let searchValue: SearchListType[] = [];

    if (selectedAssetType.length !== 0) {
      searchValue.push({
        isSearch: true,
        searchKey: "fixedAssetTypeId",
        searchValue: selectedAssetType,
      });
    }

    if (selectedFacilityData.length !== 0) {
      searchValue.push({
        isSearch: true,
        searchKey: "locatedAtFacilityId",
        searchValue: selectedFacilityData,
      });
    }

    if (selectedCategoryType.length !== 0) {
      searchValue.push({
        isSearch: true,
        searchKey: "parentTypeId",
        searchValue: selectedCategoryType,
      });
    }

    if (count === 0) {
      setAssetListOptions(initialAssetListData);
      setFilteredAssets(initialAssetListData);
      setCount((prev) => prev + 1);
    } else {
      getAssetListData(searchValue);
    }
  }, [selectedAssetType, selectedFacilityData]);

  useEffect(() => {
      const filterAssetType = selectedCategoryType.length > 0
      ? assetTypeList.filter((item) => selectedCategoryType.includes(item.parentId))
      : assetTypeList;

    setFilterAssetTypeList(filterAssetType);
    setSelectedAssetType([]);
  }, [selectedCategoryType,assetTypeList]);

  const getReportDownload = async () => {
    setRepoLoader(true);
    let dbService = new DashBoardServices();
    let postRepoOBject;

    // For Asset Category
    const fixedAssetTypeParentId =
      selectedCategoryType.length === 0
        ? "null"
        : selectedCategoryType.length === 1
        ? selectedCategoryType[0]
        : selectedCategoryType.join(",");

    // For Asset Type
    const fixedAssetTypeId =
      selectedAssetType.length === 0
        ? "null"
        : selectedAssetType.length === 1
        ? selectedAssetType[0]
        : selectedAssetType.join(",");

    // For Asset
    const fixedAssetId =
      selectedAsset.length === 0
        ? "null"
        : selectedAsset.length === 1
        ? selectedAsset[0]
        : selectedAsset.join(",");

    // For Facility
    const locatedAtFacilityId =
      selectedFacilityData.length === 0
        ? "null"
        : selectedFacilityData.length === 1
        ? selectedFacilityData[0]
        : selectedFacilityData.join(",");

    let obj = {
      fromDate: payloadDateValue.startDate,
      minVal: roundToDecimalNumber(minTemp, 8),
      maxVal: roundToDecimalNumber(maxTemp, 8),
      fixedAssetId: fixedAssetId,
      fixedAssetTypeId: fixedAssetTypeId,
      fixedAssetTypeParentId: fixedAssetTypeParentId,
      thruDate: payloadDateValue.endDate,
      contentId: data.contentId,
      reportFormat: selectedFileType.toLowerCase(),
      reportName: data.contentName,
      locatedAtFacilityId: locatedAtFacilityId,
      utility: formatSelectedUtility(selectedUtility),
      frequency: apiParams.frequency,
      range: apiParams.range,
    };
    if (
      attributeProperties.includes("TEMP_MIN") &&
      attributeProperties.includes("TEMP_MAX")
    ) {
      postRepoOBject = { ...obj };
    } else {
      let { minVal, maxVal, ...repoObj } = { ...obj };
      postRepoOBject = repoObj;
    }
    let dataFetch: any = await dbService.getReportDownload(postRepoOBject);
    let message;
    if (dataFetch === "error") message = "Failed Try Again";
    else {
      switch (dataFetch.status) {
        case 200:
          message = "Report download initiated successfully";
          setSelectedAssetObject([]);
          setAssetListOptions([]);
          setAssetTypeList([]);

          setCategoryFilterData([]);
          setFilteredAssets([]);
          setAssetTypeList([]);
          setSelectedFileType("");
          setDateRangeValue({ startDate: "", endDate: "" });
          setMinTemp(-55);
          setMaxTemp(125);
          setDateRangeValue({
            startDate: "",
            endDate: "",
          });
          setSelectedFacilityData([]);
          setSelectedAsset([]);
          setSelectedAssetType([]);
          setSelectedCategoryType([]);
          setSelectedAssetObject([]);
          setSelectedAssetTypeObject([]);
          setCardShow(false);
          break;

        case 419:
          let data_412 = await dataFetch.json();
          message = await data_412.errorMessage;
          break;

        case 204:
          let data_413 = await dataFetch.json();
          message = await data_413.errorMessage;
          break;

        case 503:
          let data_419 = await dataFetch.json();
          message = await data_419.errorMessage;
          break;

        case 512:
          let data_420 = await dataFetch.json();
          message = await data_420.errorMessage;
          break;

        case 500:
          message = "Internal server error";
          break;

        case 400:
          message = "Bad Request Error";
          break;

        default:
          message = "error";
      }
    }
    callBackRepoStatus(message);
    setRepoLoader(false);
  };

  useEffect(() => {
    if (!R.isEmpty(data)) {
      let repoListAttributes = data?.attributeProperties?.map((el: any) => {
        if (el.attributeValue === "TRUE") return el.attributeName;
      });
      setAttributeProperties(repoListAttributes);
      let list: any = data?.attributeProperties.find(
        (el: any) => el.attributeName === "FORMATS"
      );
      if (list === undefined) setFormatList([]);
      else {
        let itemsList = list.attributeValue;
        if (itemsList.includes("XLSX") && itemsList.includes("PDF"))
          setFormatList(["XLSX", "PDF"]);
        else if (itemsList.includes("XLSX")) setFormatList(["XLSX"]);
        else if (itemsList.includes("PDF")) setFormatList(["PDF"]);
        else setFormatList([]);
      }
    }
  }, [data]);

  useEffect(() => {
    const formatReportCardData = () => {
      const utils = new ReportClass();
      const result = utils.formatData(reportCardData);
      setData(result);
    };
    formatReportCardData();
  }, [reportCardData]);

  const handleDateRangeChange = (ranges: DateDataType) => {
    const fromdDate = moment(ranges.startDate).format("MM/DD/YYYY");
    const endDate = moment(ranges.endDate).format("MM/DD/YYYY");
    setPayloadDateValue({
      startDate: moment(ranges.startDate).startOf("day").valueOf(),
      endDate: moment(ranges.endDate).endOf("day").valueOf(),
    });
    setDateRangeValue({ startDate: fromdDate, endDate: endDate });
  };

  const handleDateChange = (ranges: DateDataType) => {
    const fromdDate = moment(ranges.startDate).format("MM/DD/YYYY");
    const selectedDate = moment(ranges.startDate).startOf("day").valueOf();
    const endOfDate = moment(ranges.startDate).endOf("day").valueOf();
    setPayloadDateValue({
      startDate: selectedDate,
      endDate: endOfDate,
    });
    setDateRangeValue({ startDate: fromdDate, endDate: "" });
  };

  const handleDateTimeRangeChange = (ranges: DateDataType) => {
    setPayloadDateValue({
      startDate: ranges.startDate,
      endDate: ranges.endDate,
    });
    setDateRangeValue(ranges);
  };

  const handleDateTimeChange = (epochiTime: number) => {
    setPayloadDateValue({
      startDate: epochiTime,
      endDate: epochiTime + 59 * 1000, // 59 seconds between start time and end time
    });
    setDateRangeValue({ startDate: epochiTime, endDate: epochiTime });
  };

  const handleTimeRangeChange = (ranges: TimeInputType) => {
    let startTime = dateFormatter.timeConvertedintomilliSeconds(
      ranges.startDate
    );
    let endTime = dateFormatter.timeConvertedintomilliSeconds(ranges.endDate);
    setPayloadDateValue({
      startDate: startTime,
      endDate: endTime,
    });
    setDateRangeValue(ranges);
  };

  const handleTimeChange = (ranges: TimeInputType) => {
    let startTime = dateFormatter.timeConvertedintomilliSeconds(
      ranges.startDate
    );
    setPayloadDateValue({
      startDate: startTime,
      endDate: startTime,
    });
    setDateRangeValue(ranges);
  };

  useEffect(() => {
    if (!active) {
      setSelectedFileType("");
      setMinTemp("");
      setMaxTemp("");
      setDateRangeValue({
        startDate: "",
        endDate: "",
      });
    }
  }, [active]);


//Selected Frequency of Trend Date
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [selectedChip, setSelectedChip] = useState("");

  useEffect(() => {
    let checkList = data.attributeProperties.filter((obj: any) => {
      if (obj.attributeValue === "TRUE" && obj.mandate === "Y") return obj;
    });
    let count = 0;
    for (let item of checkList) {
      if (item.attributeName === "TEMP_MIN") {
        if (minTemp !== "") count = count + 1;
      } else if (item.attributeName === "TEMP_MAX") {
        if (maxTemp !== "") count = count + 1;
      } else if (
        item.attributeName === "UTILITY" ||
        item.attributeName === "UTILITY_MS"
      ) {
        if (selectedUtility.length !== 0) count = count + 1;
      } else if (
        item.attributeName === "ASSET" ||
        item.attributeName === "ASSET_MS"
      ) {
        if (selectedAsset.length !== 0) count = count + 1;
      } else if (
        item.attributeName === "ASSET_TYPE" ||
        item.attributeName === "ASSET_TYPE_MS"
      ) {
        if (selectedAssetType.length !== 0) count = count + 1;
      } else if (
        item.attributeName === "ASSET_CATEGORY" ||
        item.attributeName === "ASSET_CATEGORY_MS"
      ) {
        if (selectedCategoryType.length !== 0) count = count + 1;

      } 
      else if (item.attributeName === "TREND_DATE_PICKER"){
        if (selectedFrequency !== "" || selectedChip !== "" ) count = count + 1;
      }
      else if (
        item.attributeName === "FACILITY" ||
        item.attributeName === "FACILITY_MS"
      ) {
        if (selectedFacilityData.length !== 0) count = count + 1;
      } else {
        if (dateRangeValue.startDate !== "") count = count + 1;
      }
    }
    setDownloadBtnStatus(count === checkList.length);
  }, [
    minTemp,
    maxTemp,
    selectedUtility,
    selectedFrequency,
    selectedChip,
    dateRangeValue,
    selectedCategoryType,
    selectedAsset,
    selectedAssetType,
    selectedFacilityData,
  ]);

  const CheckboxOption = (props: any) => {
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ marginRight: 10 }}
        />
        <label>{props.label}</label>
      </components.Option>
    );
  };

  const MultiValueSelector = (props: any) => {
    return (
      <components.MultiValue {...props}>
        <span>{props.data.label}</span>
      </components.MultiValue>
    );
  };

  // For Trend Date Picker
  const frequenciesData = {
    Day: [
      "15 min",
      "30 min",
      "60 min",
      "Past 7 days",
      "Past 14 days",
      "Past 30 days",
      "Past 60 days",
      "Past 90 days",
    ],
    Week: ["Past 4 weeks", "Past 8 weeks", "Past 12 weeks"],
    Month: [
      "Past 3 months",
      "Past 6 months",
      "Past 9 months",
      "Past 12 months",
    ],
  };

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedRange, setSelectedRange] = useState("");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [apiParams, setApiParams] = useState({
    frequency: "",
    range: "",
  });

  const handleInputClick = () =>{setShowDatePicker(true);setSelectedChip("Day")} ;
  const handleCancelClick = () => {
    setShowDatePicker(false);
    setSelectedChip("")
    setSelectedRange("");
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  const handleApplyClick = () => {
    const formattedRange = `${moment(state[0].startDate).format(
      "DD/MM/YYYY"
    )} - ${moment(state[0].endDate).format("DD/MM/YYYY")}`;
    setSelectedRange(formattedRange);
    const startDateEpoch = moment(state[0].startDate).valueOf();
    const endDateEpoch = moment(state[0].endDate).valueOf();
    setPayloadDateValue({
      startDate: startDateEpoch,
      endDate: endDateEpoch,
    });
    setShowDatePicker(false);
  };

  const handleChipClick = (chip) => {
    setSelectedChip(chip);
    setSelectedFrequency("");

    const today = new Date();

    if (chip === "Week") {
      const lastSunday = new Date(
        today.setDate(today.getDate() - today.getDay())
      );
      setState([
        {
          startDate: lastSunday,
          endDate: new Date(),
          key: "selection",
        },
      ]);
    } else {
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
  };

  const handleFrequencyClick = (
    frequency: string,
    customRange: { startDate: Date; endDate: Date } | null = null
  ) => {
    setSelectedFrequency(frequency);
    let startDate = new Date();
    let endDate = new Date();
    let apiFrequency = "";
    let apiRange = "";

    if (customRange) {
      const { startDate: customStartDate, endDate: customEndDate } =
        customRange;
      apiFrequency = "MONTH";
      apiRange = "3";
      startDate = customStartDate;
      endDate = customEndDate;
    } else if (selectedChip === "Day") {
      switch (frequency) {
        case "15 min":
        case "30 min":
        case "60 min":
          apiFrequency = "MINUTE";
          apiRange = frequency.split(" ")[0];
          break;
        case "Past 7 days":
          apiFrequency = "DAY";
          apiRange = "7";
          startDate = subDays(new Date(), 6);
          break;
        case "Past 14 days":
          apiFrequency = "DAY";
          apiRange = "14";
          startDate = subDays(new Date(), 13);
          break;
        case "Past 30 days":
          apiFrequency = "DAY";
          apiRange = "30";
          startDate = subDays(new Date(), 29);
          break;
        case "Past 60 days":
          apiFrequency = "DAY";
          apiRange = "60";
          startDate = subDays(new Date(), 59);
          break;
        case "Past 90 days":
          apiFrequency = "DAY";
          apiRange = "90";
          startDate = subDays(new Date(), 89);
          break;
        default:
          break;
      }
    } else if (selectedChip === "Week") {
      const today = new Date();
      const lastSunday = new Date(
        today.setDate(today.getDate() - today.getDay())
      );
      switch (frequency) {
        case "Past 4 weeks":
          apiFrequency = "WEEK";
          apiRange = "4";
          startDate = subWeeks(lastSunday, 3);
          break;
        case "Past 8 weeks":
          apiFrequency = "WEEK";
          apiRange = "8";
          startDate = subWeeks(lastSunday, 7);
          break;
        case "Past 12 weeks":
          apiFrequency = "WEEK";
          apiRange = "12";
          startDate = subWeeks(lastSunday, 11);
          break;
        default:
          break;
      }
      endDate = new Date();
    } else if (selectedChip === "Month") {
      switch (frequency) {
        case "Past 3 months":
          apiFrequency = "MONTH";
          apiRange = "3";
          startDate = subMonths(new Date(), 2);
          break;
        case "Past 6 months":
          apiFrequency = "MONTH";
          apiRange = "6";
          startDate = subMonths(new Date(), 5);
          break;
        case "Past 9 months":
          apiFrequency = "MONTH";
          apiRange = "9";
          startDate = subMonths(new Date(), 8);
          break;
        case "Past 12 months":
          apiFrequency = "MONTH";
          apiRange = "12";
          startDate = subMonths(new Date(), 11);
          break;
        default:
          break;
      }
    }
    setApiParams({
      frequency: apiFrequency,
      range: apiRange,
    });
    setState([{ startDate, endDate, key: "selection" }]);
  };

  const handleRangeChange = (item) => {
    const { startDate, endDate } = item.selection;
    const maxDate = new Date();
    const minDate = dateFormatter.get365DaysAgo(); 

    if (!["15 min", "30 min", "60 min"].includes(selectedFrequency)) {
      setSelectedFrequency("");
    }
    
    if (selectedChip === "Week") {
      let startOfWeek = moment(startDate).startOf("week").toDate();
      let endOfWeek = moment(endDate).endOf("week").toDate();
  
      startOfWeek = moment(startOfWeek).isBefore(minDate) ? minDate : startOfWeek;
      endOfWeek = moment(endOfWeek).isAfter(maxDate) ? maxDate : endOfWeek;
  
      setState([
        {
          startDate: startOfWeek,
          endDate: endOfWeek,
          key: "selection",
        },
      ]);
  
      setApiParams({
        frequency: "WEEK",
        range: "4",
      });
    } else if (["15 min", "30 min", "60 min"].includes(selectedFrequency)) {
      const daysDifference = moment(endDate).diff(moment(startDate), "days");
  
      if (daysDifference >= 7) {
        setState([
          {
            startDate: startDate,
            endDate: addDays(startDate, 6),
            key: "selection",
          },
        ]);
      } else if (moment(startDate).isAfter(maxDate)) {
        setState([
          {
            startDate: subDays(maxDate, 6),
            endDate: maxDate,
            key: "selection",
          },
        ]);
      } else {
        setState([item.selection]);
      }
    } else {
      const adjustedStartDate = moment(startDate).isAfter(maxDate) ? maxDate : startDate;
      const adjustedEndDate = moment(endDate).isAfter(maxDate) ? maxDate : endDate;
  
      setState([
        {
          startDate: adjustedStartDate,
          endDate: adjustedEndDate,
          key: "selection",
        },
      ]);
  
      let apiFrequency = "";
      let apiRange = "";
  
      if (selectedChip === "Day") {
        apiFrequency = "DAY";
        apiRange = "7";
      } else if (selectedChip === "Month") {
        apiFrequency = "MONTH";
        apiRange = "3";
      }
  
      setApiParams({
        frequency: apiFrequency,
        range: apiRange,
      });
    }
  };
    

  const getComponent = (item: ReportResponseCard, id: number) => {
    switch (item.attributeName) {
      case "TEMP_MIN":
        return (
          <TempInputFied
            key={id}
            abbreviation={item.uomAbbr}
            validationType={item.fieldTypeId}
            field={item.mandate}
            title="Min Temperature"
            value={minTemp}
            callBackFunction={(e) => setMinTemp(e)}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "TEMP_MAX":
        return (
          <TempInputFied
            key={id}
            abbreviation={item.uomAbbr}
            validationType={item.fieldTypeId}
            field={item.mandate}
            title="Max Temperature"
            value={maxTemp}
            callBackFunction={(e) => setMaxTemp(e)}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "DATE_RANGE":
        return (
          <DateRangeSelectorInput
            key={id}
            field={item.mandate}
            state={item.attributeName === activeInput}
            title="Date Range"
            PlaceHolder=""
            value={dateRangeValue}
            updateDateRange={handleDateRangeChange}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "DATE":
        return (
          <ReportDatePicker
            key={id}
            field={item.mandate}
            state={item.attributeName === activeInput}
            title="Date"
            PlaceHolder=""
            value={dateRangeValue}
            updateDateRange={handleDateChange}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "DATE_TIME_RANGE":
        return (
          <DateTimeRangeSelectorInput
            key={id}
            state={item.attributeName === activeInput}
            field={item.mandate}
            title="Date Time Range"
            PlaceHolder={""}
            value={dateRangeValue}
            updateDateRange={handleDateTimeRangeChange}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "DATE_TIME":
        return (
          <ReportDateTimePicker
            key={id}
            state={item.attributeName === activeInput}
            field={item.mandate}
            title="Date Time"
            PlaceHolder={""}
            value={dateRangeValue}
            updateDateRange={handleDateTimeChange}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "TREND_DATE_PICKER":
        return (
          <TrendDatePicker
            frequenciesData={frequenciesData}
            showDatePicker={showDatePicker}
            selectedRange={selectedRange}
            selectedChip={selectedChip}
            state={state}
            selectedFrequency={selectedFrequency}
            handleInputClick={handleInputClick}
            handleCancelClick={handleCancelClick}
            handleApplyClick={handleApplyClick}
            handleChipClick={handleChipClick}
            handleFrequencyClick={handleFrequencyClick}
            handleRangeChange={handleRangeChange}
          />
        );
      case "TIME_RANGE":
        return (
          <TimeRangePicker
            key={id}
            state={item.attributeName === activeInput}
            field={item.mandate}
            title="Time Range"
            PlaceHolder={""}
            value={dateRangeValue}
            updateDateRange={handleTimeRangeChange}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "TIME":
        return (
          <TimePicker
            key={id}
            state={item.attributeName === activeInput}
            field={item.mandate}
            title="Time"
            PlaceHolder={""}
            value={dateRangeValue}
            updateDateRange={handleTimeChange}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "UTILITY":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Utility ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={reportDropDownCustomStyle}
              placeholder="Select Utility"
              options={utilityData}
              onChange={(selected) => setSelectedUtility(selected?.value || "")}
            />
          </div>
        );
      case "UTILITY_MS":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Utility ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={{
                ...reportDropDownCustomStyle,
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "transparent"
                    : provided.backgroundColor,
                  color: "black",
                  display: "flex",
                }),
              }}
              placeholder="Select Utilities"
              options={utilityData}
              onChange={(selected) => {
                setSelectedUtility(
                  selected ? selected.map((option) => option.value) : []
                );
              }}
              isClearable
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option: CheckboxOption,
                MultiValue: MultiValueSelector,
              }}
            />
          </div>
        );
      case "ASSET_CATEGORY":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Asset Category ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={reportDropDownCustomStyle}
              placeholder="Select Category"
              onFocus={() => setActiveInput(item.attributeName)}
              onChange={(selectedOption: { value: string }) =>
                selectedOption?setSelectedCategoryType([selectedOption?.value]):setSelectedCategoryType([])
              }
              options={categoryFilterData?.map((asset) => ({
                value: asset.id,
                label: asset.name,
                key: asset.id,
              }))}
              isClearable
              onKeyDown={handleKeyDown}
            />
          </div>
        );
      case "ASSET_CATEGORY_MS":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Asset Category ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={{
                ...reportDropDownCustomStyle,
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "transparent"
                    : provided.backgroundColor,
                  color: "black",
                  display: "flex",
                }),
              }}
              placeholder="Select Category"
              onFocus={() => setActiveInput(item.attributeName)}
              onChange={(selectedOptions) => {
                if (selectedOptions) {
                  setSelectedCategoryType(
                    selectedOptions.map((option) => option.value)
                  );
                } else {
                  setSelectedCategoryType([]);
                }
              }}
              options={categoryFilterData?.map((asset) => ({
                value: asset.id,
                label: asset.name,
                key: asset.id,
              }))}
              hideSelectedOption={false}
              onKeyDown={handleKeyDown}
              isClearable
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option: CheckboxOption,
                MultiValue: MultiValueSelector,
              }}
              onInputChange={(inputValue: string, { action }) => {
                searchAsset(inputValue);
              }}
            />
          </div>
        );
      case "ASSET_TYPE":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Asset Type ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={reportDropDownCustomStyle}
              placeholder="Select Asset Type"
              onFocus={() => setActiveInput(item.attributeName)}
              onChange={(selectedOption: { value: string }) => {
                setSelectedAssetType([selectedOption?.value]);
                setSelectedAssetTypeObject([selectedOption]);
              }}
              options={filterAssetTypeList.map((item) => ({
                value: item.id,
                label: item.name,
                key: item.id,
              }))}
              value={selectedAssetType.length ? { value: selectedAssetType[0], label: selectedAssetTypeObject[0]?.label } : null}
              isClearable
              onKeyDown={handleKeyDown}
            />
          </div>
        );
      case "ASSET_TYPE_MS":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Asset Type ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={{
                ...reportDropDownCustomStyle,
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "transparent"
                    : provided.backgroundColor,
                  color: "black",
                  display: "flex",
                }),
              }}
              placeholder="Select Asset Type"
              onFocus={() => setActiveInput(item.attributeName)}
              onChange={(selectedOption) => {
                setSelectedAssetTypeObject(selectedOption);

                if (selectedOption) {
                  setSelectedAssetType(
                    selectedOption.map((option) => option.value)
                  );
                } else {
                  setSelectedAssetType([]);
                }
              }}
              options={filterAssetTypeList.map((item) => ({
                value: item.id,
                label: item.name,
                key: item.id,
              }))}
              value={selectedAssetType.length ? selectedAssetType.map((value, index) => ({ value, label: selectedAssetTypeObject[index]?.label })) : []}
              isClearable
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option: CheckboxOption,
                MultiValue: MultiValueSelector,
              }}
              onInputChange={(inputValue: string, { action }) => {
                searchAsset(inputValue);
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
        );
      case "ASSET":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Asset ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={reportDropDownCustomStyle}
              placeholder="Select Asset"
              options={filteredAssets}
              onFocus={() => setActiveInput(item.attributeName)}
              onChange={(selectedOption: any) => {
                setSelectedAsset([selectedOption.value]);
                setSelectedAssetObject(selectedOption);
              }}
              onInputChange={(inputValue: string, { action }) => {
                searchAsset(inputValue);
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
        );
      case "ASSET_MS":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Asset ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={{
                ...reportDropDownCustomStyle,
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "transparent"
                    : provided.backgroundColor,
                  color: "black",
                  display: "flex",
                }),
              }}
              placeholder="Select Asset"
              options={filteredAssets}
              onFocus={() => setActiveInput(item.attributeName)}
              onChange={(selectedOption: any) => {
                setSelectedAssetObject(selectedOption);
                if (selectedFacilityData) {
                  setSelectedAsset([]);
                  setSelectedAssetObject([]);
                }

                if (selectedOption) {
                  setSelectedAsset(
                    selectedOption.map((option) => option.value)
                  );
                  setSelectedAssetObject(selectedOption);
                } else {
                  setSelectedAsset([]);
                  setFilteredAssets([]);
                }
              }}
              value={selectedAssetObject}
              isClearable
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option: CheckboxOption,
                MultiValue: MultiValueSelector,
              }}
              onInputChange={(inputValue: string, { action }) => {
                searchAsset(inputValue);
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
        );
      case "FACILITY":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Facility ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={reportDropDownCustomStyle}
              placeholder="Select Facility"
              onFocus={() => setActiveInput(item.attributeName)}
              onChange={(selectedOption: { value: string }) =>
                setSelectedFacilityData([selectedOption?.value])
              }
              options={facilityListData}
              isClearable
              onKeyDown={handleKeyDown}
            />
          </div>
        );
      case "FACILITY_MS":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Facility ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={{
                ...reportDropDownCustomStyle,
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "transparent"
                    : provided.backgroundColor,
                  color: "black",
                  display: "flex",
                }),
              }}
              placeholder="Select Facility"
              onFocus={() => setActiveInput(item.attributeName)}
              onChange={(selectedOption: any) => {
                if (selectedOption) {
                  setSelectedFacilityData(
                    selectedOption.map((option) => option.value)
                  );
                  setSelectedAsset([]);
                  setSelectedAssetObject([]);
                } else {
                  setSelectedFacilityData([]);
                }
              }}
              options={facilityListData}
              isClearable
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option: CheckboxOption,
                MultiValue: MultiValueSelector,
              }}
              onInputChange={(inputValue: string, { action }) => {
                searchAsset(inputValue);
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
        );
      case "ASSET_MS":
      default:
        return <></>;
    }
  };

  const setFileType = (fileType: string) => {
    setSelectedFileType(fileType);
    activeState();
    setCardShow(true);
    setSelectedAssetObject([]);
  };

  const closeFileType = () => {
    setSelectedFileType("");
    setSelectedFileType("");
    setMinTemp("");
    setMaxTemp("");
    setDateRangeValue({
      startDate: "",
      endDate: "",
    });
    setSelectedAsset("null");
    setSelectedAssetType([]);
    setSelectedCategoryType([]);
    setSelectedAssetObject(null);
    setSelectedAssetTypeObject([]);
    setCardShow(false);
  };

  return (
    <div className="reportDataMainView">
      <div className="viewReportCard">
        <div className="reportDetailsView">
          <p className="reportListHeader">{data.contentName}</p>
        </div>
        <div className="downloadActionView">
          <div className="reportDataFileType">
            {formatList &&
              formatList.map((item: string, index: number) => {
                return (
                  <div
                    key={index}
                    className={`selectedFileFormatDiv  ${
                      selectedFileType === item ? "activeSelected" : ""
                    }`}
                    onClick={() => setFileType(item)}
                  >
                    <img src={downloadIcon} loading="lazy" alt="icon" />{" "}
                    <p className="fileFormatText">{item}</p>
                  </div>
                );
              })}
          </div>
          {/* <img src={pinIcon} loading="lazy" alt="icon"/> */}
        </div>
      </div>

      {active && cardShow && (
        <div className="downloadReportPopUp">
          <div className="columnRightDiv">
            {data.attributeProperties.map(
              (obj: ReportResponseCard, index: number) => {
                if (obj.attributeValue === "TRUE")
                  return getComponent(obj, index);
              }
            )}
          </div>

          <div className="columnLeftDiv">
            {repoLoader ? (
              <div className="downloadButton">
                <p>Please wait...</p>
              </div>
            ) : downloadBtnStatus ? (
              <div className="downloadButton" onClick={getReportDownload}>
                <img src={DownloadIcon} alt="icon" /> <p>Download</p>
              </div>
            ) : (
              <div className="downloadButton inActivedownloadButton">
                <img src={DownloadIcon} alt="icon" /> <p>Download</p>
              </div>
            )}
            <img
              src={CloseIcon}
              loading="lazy"
              alt="icon"
              className="closeIcon"
              onClick={closeFileType}
            />
          </div>
        </div>
      )}
    </div>
  );
};
