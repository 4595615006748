import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../../Constants/constants';
import LocalStorageService from '../../../../../Utils/LocalStorageService';
import { List } from 'echarts';
export const ViewUserPostData = async (
  payLoadObj: {
    viewIndex:string;
    viewSize:string;
    extraDetails:boolean;
    searchBy:List;
  },
) => {
  const token = LocalStorageService.getToken("@tokenID");
  const url = `${API_BASE_URL}user/getUserList`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payLoadObj),
    });

    if (!response.ok) {
      throw new Error(`Server Error: ${response.status} - ${response.statusText}`);
    }

    const responseData = await response.json();
    console.log("Response Data:", JSON.stringify(responseData, null, 2));
  } catch (error) {
    console.error("Error:", error);
    return { error: error.message };
  }
};

export const EditUserResetPassword = async (userName, partyId) => {
  const tokenId = LocalStorageService.getToken("@tokenID");
  const url1 = `${API_BASE_URL}user/resetPassword`;
  try {
    const editResponse = await fetch(url1, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${tokenId}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "userLoginIds": [userName],
        "userPartyId": partyId
      }),
    });
    if (!editResponse.ok) {
      const errorData = await editResponse.json();
      toast.error(`${editResponse.status}`+"Password not reset,check with you email id ")
      throw new Error(errorData.message || `Server Error: ${editResponse.status} - ${editResponse.statusText}`);
    }
    return { success: true };
  } catch (error) {
    console.error("Error:", error);
    return { error: error.message };
  }
};

export const AssignUserFacility = async (payLoadAssignUser) => {
  const token = LocalStorageService.getToken("@tokenID");
  const url = `${API_BASE_URL}person/assignFacility`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payLoadAssignUser),
    });
    if (response.ok) {
      const responseData = await response.json();
      toast.success("Assigned User to facility Successfully");     
       console.log("Response Data (AssignUserFacility):", JSON.stringify(responseData, null, 2));
      return responseData;  
    } else {
      toast.error(`${response.status}`+" : failed to assign user to facility")
      console.error(`Server Error in AssignUserFacility: ${response.status} - ${response.statusText}`);
      return { error: `Server Error: ${response.status} - ${response.statusText}` };
    }
  } catch (error) {
    console.error("Error in AssignUserFacility:", error);
    return { error: error.message };
  }
};
export const AssignUserFacilityGroups = async (payLoadAssignUserFacGrp) => {
  const token = LocalStorageService.getToken("@tokenID");
  const url = `${API_BASE_URL}person/assignFacilityGroup`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payLoadAssignUserFacGrp),
    });
    if (response.ok) {
      const responseData = await response.json();
      toast.success("Assigned User to facility group Successfully");     
      console.log("Response Data (AssignUserFacilityGroups):", JSON.stringify(responseData, null, 2));
      return responseData;  
    } else {
      toast.error(`${response.status}`+" : failed to assign user to facility group")
      console.error(`Server Error in AssignUserFacilityGroups: ${response.status} - ${response.statusText}`);
      return { error: `Server Error: ${response.status} - ${response.statusText}` };
    }
  } catch (error) {
    console.error("Error in AssignUserFacilityGroups:", error);
    return { error: error.message };
  }
};

export const DisableUserApi = async (payLoadDisableUser) => {
  const token = LocalStorageService.getToken("@tokenID");
  const url = `${API_BASE_URL}user/disableUser`;

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payLoadDisableUser),
    });
    if (response.ok) {
      const responseData = await response.json();
      console.log("Response Data (DisableUserApi):", JSON.stringify(responseData, null, 2));
      return responseData;  
    } else {
      console.error(`Server Error in DisableUserApi: ${response.status} - ${response.statusText}`);
      return { error: `Server Error: ${response.status} - ${response.statusText}` };
    }
  } catch (error) {
    console.error("Error in DisableUserApi:", error);
    return { error: error.message };
  }
};
